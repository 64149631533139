import api from "./api";
import { Pagination } from "./association";

export type Subscription = {
  id?: string;
  start_validity?: string;
  end_validity?: string;
  partnerId?: string;
  associationId: string;
  status: "new" | "active" | "pending" | "not active";
};

export type ChangeSubscription = {
  id: string;
  start_validity?: string;
  end_validity?: string;
  status: "new" | "active" | "pending" | "not active";
  sendEmail?: boolean;
};

export type acceptedSubscription = {
  accepted: boolean;
  subId: string;
};

export const getSubscriptionByPartner = (
  id: string,
  pagination: Pagination
) => {
  return api.get("/subscription", {
    params: { id: id, take: pagination.take, page: pagination.page },
  });
};

export const getSubscriptionById = (id: string) => {
  return api.get("/subscription/" + id);
};

export const createSubscription = (subscription: Subscription) => {
  return api.post("/subscription", subscription);
};

export const changeSubscription = (subscription: ChangeSubscription) => {
  return api.put("/subscription", subscription);
};

export const acceptedPrivacy = (accepted: boolean, idSub: string) => {
  return api.post("/subscription/data-treatment", {
    accepted: accepted,
    subId: idSub,
  });
};

export const downloadPdf = (idSubscrition: string) => {
  return api.get("/subscription/download-pdf", {
    responseType: "blob",
    params: { id: idSubscrition },
  });
};
