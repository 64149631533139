import React, { useContext, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, Outlet, useNavigate } from "react-router-dom";

import logo from "../../assets/logo_etsapp.png";

import style from "./navBarStyle.module.scss";
import PATHS from "../../utils/paths";
import { authContext } from "../../context/auth";

import BreadcrumbsCustom from "../Breadcrumbs/BreadcrumbsCustom";
import SnackMenu from "../SnackMenu/SnackMenu";

function NavBar() {
  const navigate = useNavigate();
  const { user } = useContext(authContext);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <div style={{ position: "fixed", width: "100%", zIndex: 10 }}>
        <AppBar className={style.navBar} position="static">
          <Toolbar className={style.toolbar}>
            <img
              onClick={() => navigate("/")}
              className={style.logo}
              src={logo}
              alt="logo"
            />

            {!user ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <List dense className={style.buttons}>
                    <ListItem disablePadding>
                      <ListItemButton
                        focusVisibleClassName={style.button}
                        classes={{ selected: style.selected }}
                        component={Link}
                        to={PATHS.socioLogin}
                      >
                        <Typography
                          className={style.text}
                          variant="caption"
                          component="p"
                        >
                          Login Socio
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                  <List dense className={style.buttons}>
                    <ListItem disablePadding>
                      <ListItemButton
                        classes={{ selected: style.selected }}
                        component={Link}
                        to={PATHS.associationLogin}
                      >
                        <Typography
                          className={style.text}
                          variant="caption"
                          component="p"
                        >
                          Login Associazione
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </div>
              </div>
            ) : (
              <Box sx={{ flexGrow: 0 }}>
                <IconButton
                  onClick={(event) => {
                    setAnchorElUser(event.currentTarget);
                  }}
                  sx={{ p: 0 }}
                >
                  <Avatar alt="" src="/static/images/avatar/2.jpg" />
                </IconButton>
                <SnackMenu
                  anchorElUser={anchorElUser}
                  onClose={() => setAnchorElUser(null)}
                />
              </Box>
            )}
          </Toolbar>
        </AppBar>

        <BreadcrumbsCustom />
      </div>

      <Outlet />
    </Box>
  );
}

export default NavBar;
