import { useContext, useEffect, useState } from "react";

import style from "./settingAdminStyle.module.scss";

//api
//component function
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

//mui
import { Chip, IconButton, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { authContext } from "../../context/auth";
import { Type, addType, deleteType, getType } from "../../models/type";

function SettingAdmin() {
  const { id } = useParams();
  const { user } = useContext(authContext);

  const [types, setTypes] = useState<Type[]>([]);
  const [newType, setNewType] = useState<string>("");

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getType().then((response) => {
      setTypes(response.data);
    });
  }

  return (
    <div className={style.page + " pageContainer pageCenter cardInfo"}>
      <section
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">Tipologie Associazioni</Typography>
      </section>
      <div
        className={style.service}
        style={{
          width: "800px",
          height: "350px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            overflowY: "scroll",
          }}
        >
          {types.length > 0 ? (
            types.map((element) => {
              return (
                <Chip
                  label={element.name}
                  onDelete={() => {
                    deleteType(element.id).then(() => getData());
                  }}
                />
              );
            })
          ) : (
            <section>
              <Typography>Nessuna tipologia disponibile</Typography>
            </section>
          )}
        </div>
        <TextField
          value={newType}
          variant="standard"
          onChange={(e) => {
            setNewType(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  if (newType !== "")
                    addType(newType).then(() => {
                      setNewType("");
                      getData();
                    });
                }}
              >
                <AddCircleOutlinedIcon />
              </IconButton>
            ),
          }}
        />
      </div>
    </div>
  );
}

export default SettingAdmin;
