import { Menu, MenuItem, MenuList, Typography } from "@mui/material";
import React, { useContext } from "react";
import { RoleNames } from "../../utils/roles";
import { authContext } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import PATHS from "../../utils/paths";

interface Props {
  anchorElUser: null | HTMLElement;
  onClose: () => void;
}

function SnackMenu({ anchorElUser, onClose }: Props) {
  const { user, logout } = useContext(authContext);
  const navigate = useNavigate();
  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={onClose}
    >
      {user?.role === RoleNames.ADMIN && (
        <div>
          <MenuItem
            onClick={() => {
              onClose();
              navigate(PATHS.adminAssociationList);
            }}
          >
            <Typography textAlign="center">Associazioni</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClose();
              navigate(PATHS.settingsAdmin);
            }}
          >
            <Typography textAlign="center">Impostazioni</Typography>
          </MenuItem>
        </div>
      )}
      {user?.role === RoleNames.ASSOCIATION && (
        <div>
          <MenuItem
            onClick={() => {
              onClose();
              navigate(PATHS.associationProfile + "/" + user.id);
            }}
          >
            <Typography textAlign="center">Profilo</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClose();
              navigate(PATHS.partnerList);
            }}
          >
            <Typography textAlign="center">Soci</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClose();
              navigate(PATHS.ticketListAssociation + "/" + user.id);
            }}
          >
            <Typography textAlign="center">Ticket</Typography>
          </MenuItem>
        </div>
      )}
      {user?.role === RoleNames.PARTNER && (
        <div>
          <MenuItem
            onClick={() => {
              onClose();
              navigate(PATHS.partnerProfile + "/" + user.id);
            }}
          >
            <Typography textAlign="center">Profilo</Typography>
          </MenuItem>
        </div>
      )}
      <MenuItem
        onClick={() => {
          onClose();
          logout();
        }}
      >
        <Typography textAlign="center">Logout</Typography>
      </MenuItem>
    </Menu>
  );
}

export default SnackMenu;
