import React, { useContext, useEffect, useState } from "react";
import style from "./ticketChatStyle.module.scss";
import { Button, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  closeTicket,
  createNewTicket,
  getMessagesByTicket,
  Messages,
  sendMessages,
} from "../../models/ticket";
import { authContext } from "../../context/auth";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import { stringDate } from "../../utils/stringDate";
import CloseIcon from "@mui/icons-material/Close";

function TicketChat() {
  const { user } = useContext(authContext);
  const { idTicket, id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [text, setText] = useState<string>("");
  const [messages, setMessages] = useState<Messages[]>([]);

  const [ticketOpen, setTicketOpen] = useState<boolean>(true);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (location.state !== null) {
      setTicketOpen(location.state.status === "open");
    }
    idTicket && getData(idTicket);
  }, []);

  function getData(id: string) {
    getMessagesByTicket(id).then((response) => {
      const data: Messages[] = [];
      response.data.forEach((element: any) => {
        data.push({
          id: element.id,
          text: element.text,
          date: stringDate(element.date),
          sender: element.sender,
        });
      });
      setMessages(data);
    });
  }

  return (
    <div className="pageContainer">
      <div className={style.flexContainer}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
        >
          Indietro <CloseIcon />
        </Button>
        {ticketOpen && (
          <div className={style.closeTicket}>
            <Button
              onClick={() => setOpen(true)}
              color="error"
              variant="contained"
            >
              <Typography variant="button">Chiudi Ticket</Typography>
            </Button>
          </div>
        )}
      </div>
      <section className={style.messagesContainer}>
        {messages.map((element, key) => {
          const spaceStyle: boolean =
            messages[key].sender !== messages[key - 1]?.sender;

          //space style mi dice il PRIMO
          const msnContinue =
            messages[key].sender === messages[key + 1]?.sender;

          return (
            <div
              key={element.id}
              style={{ marginTop: spaceStyle ? "10px" : "" }}
              className={`${
                element.sender === user?.role ? style.msnYou : style.msnOther
              } ${msnContinue ? style.msnContinue : ""}`}
            >
              <Typography variant="caption" component={"p"}>
                {element.date}
              </Typography>
              <Typography variant="body1" component={"p"}>
                {element.text}
              </Typography>
            </div>
          );
        })}
      </section>
      {ticketOpen && (
        <div className={style.containerinput}>
          <TextField
            value={text}
            className={style.input}
            multiline={true}
            minRows={3}
            maxRows={3}
            onChange={(event) => setText(event.target.value)}
          ></TextField>

          <div>
            <Button
              className={style.button}
              onClick={() => {
                if (!text) return;
                idTicket
                  ? sendMessages({ ticketId: idTicket!, text: text }).then(
                      () => {
                        setText("");
                        getData(idTicket);
                      }
                    )
                  : createNewTicket({
                      associationId: id!,
                      message: { text: text },
                    }).then((response) => {
                      setText("");
                      getData(response.data.id);
                      navigate(response.data.id);
                    });
              }}
              variant="contained"
              endIcon={<SendIcon />}
            >
              invia
            </Button>
          </div>
        </div>
      )}
      <ModalConfirm
        onClose={() => setOpen(false)}
        // TODO: navigation may be too slow some times
        onConfirm={() => closeTicket(idTicket!).then(() => navigate(-1))}
        open={open}
        onCancel={() => setOpen(false)}
        title="Sei sicuro di voler Chiudere il ticket?"
      />
    </div>
  );
}

export default TicketChat;
