import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import { Association } from "../../models/association";
import { useNavigate } from "react-router-dom";
import PATHS from "../../utils/paths";

import style from "./customCardStyle.module.scss";

interface Props {
  association: Association;
}
const imageStock =
  "https://fotografiaartistica.it/wp-content/uploads/2021/07/come-fotografare-nuoto-sport-piscina-trucchi-consigli-foto-fotografie.jpg";

function CustomCard({ association }: Props) {
  const navigate = useNavigate();
  return (
    <Card className={style.card}>
      <CardActionArea
        style={{ borderRadius: "15px" }}
        onClick={() =>
          navigate(PATHS.associationProfile + "/" + association.id)
        }
      >
        <CardMedia
          className={style.media}
          // image={association.img}
          image={association.logo ? association.logo : imageStock}
          title="association image"
        >
          <Chip style={{ margin: "5px" }} className="chipType" label="Sport" />
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {association.name}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {`${association.address}, ${association.city}, ${association.province}`}
          </Typography>
          <Typography variant="h5" className={style.cost}>
            {association.price?.price && <b>{association.price?.price} €</b>}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default CustomCard;
