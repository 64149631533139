import { Alert, Snackbar } from "@mui/material";
import React, { useContext } from "react";
import { authContext } from "../../context/auth";
import { RoleNames } from "../../utils/roles";

interface Props {
  status: boolean;
}

function SnackNotActive({ status }: Props) {
  const { user } = useContext(authContext);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={
        user !== null &&
        !status &&
        (user.role === RoleNames.ASSOCIATION || user.role === RoleNames.ADMIN)
      }
    >
      <Alert variant="filled" severity="warning">
        Associazione non attiva, completare il profilo dell'Associazione e
        contattare l'admin
      </Alert>
    </Snackbar>
  );
}

export default SnackNotActive;
