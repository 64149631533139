import api from "./api";
import { Pagination } from "./association";

export type Service = {
  id?: string;
  name: string;
  associationId?: string;
};

export type ServicePayment = {
  associationId?: string;
  partnerId: string;
  serviceId: string;
  price: number;
  start_date: string;
  end_date: string;
};

export const getServiceById = (associationId: string) => {
  return api.get("/service/", { params: { id: associationId } });
};

export const createServiceById = (service: Service) => {
  return api.post("/service/", service);
};

export const updateServiceById = (id: string, service: string) => {
  return api.put("/service/" + id, { name: service });
};

export const deleteServiceById = (id: string) => {
  return api.delete("/service/" + id);
};

export const getServicePayment = (
  idAssocioation: string,
  idPartner: string,
  pagination: Pagination
) => {
  return api.get("service/payment", {
    params: {
      associoationId: idAssocioation,
      partnerId: idPartner,
      take: pagination.take,
      page: pagination.page,
    },
  });
};

export const postServicePayment = (servicePayment: ServicePayment) => {
  return api.post("/partner/service-payment-create", servicePayment);
};
