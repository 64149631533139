import React, { CSSProperties, HTMLInputTypeAttribute } from "react";
import { Box, TextField, Typography } from "@mui/material";

import style from "./inputCustomStyle.module.scss";

interface Props {
  placeholder?: string;
  label: string;
  onChange: (value: string) => void;
  type?: HTMLInputTypeAttribute;
  icon?: JSX.Element;
  value: string | number;
  disabled?: boolean;
  styleInput?: CSSProperties;
  error?: boolean;
  variant?: "outlined" | "standard" | "filled" | undefined;
  onClick?: () => void;
}

function InputCustom({
  placeholder,
  label,
  error,
  onChange,
  value,
  icon,
  type = "",
  disabled = false,
  styleInput,
  variant = "outlined",
  onClick,
}: Props) {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
      {icon && <div className={style.icon}>{icon}</div>}
      {disabled ? (
        <div className={style.textInfo}>
          <span>{label}</span>
          <Typography
            onClick={() => onClick && onClick()}
            style={{ ...styleInput, cursor: onClick ? "pointer" : "" }}
            component="p"
          >
            {value}
          </Typography>
        </div>
      ) : (
        <TextField
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
          }}
          style={{ width: "100%" }}
          variant={variant}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          type={type}
          label={label}
          error={error ? error : false}
          size="small"
          onChange={(e) => onChange(e.target.value)}
          inputProps={{ style: styleInput }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </Box>
  );
}

export default InputCustom;
