import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { authContext } from "../../context/auth";
//utils
import PATHS from "../../utils/paths";
//component function
import NavBar from "../NavBar/NavBar";
//pages
import Home from "../../pages/Home/Home";
import AssociationList from "../../pages/AssociationList/AssociationList";
import AssociationLogin from "../../pages/AssociationLogin/AssociationLogin";
import ResetPassword from "../../pages/ResetPassword/ResetPassword";
import SocioLogin from "../../pages/SocioLogin/SocioLogin";
import AssociationSignin from "../../pages/AssociationSignin/AssociationSignin";
import AdminAssociationList from "../../pages/AdminAssociationList/AdminAssociationList";
import TicketList from "../../pages/TicketList/TicketList";
import AdminPaymentsList from "../../pages/AdminPaymentsList/AdminPaymentsList";
import AssociationEdit from "../../pages/AssociationEdit/AssociationEdit";
import AdminLogin from "../../pages/AdminLogin/AdminLogin";
import TicketChat from "../../pages/TicketChat/TicketChat";
import PartnerList from "../../pages/PartnerList/PartnerList";
import { RoleNames } from "../../utils/roles";
import PartnerEdit from "../../pages/PartnerEdit/PartnerEdit";
import { me } from "../../models/user";
import PaymentPage from "../../pages/PaymentPage/PaymentPage";
import PredefinedCheckoutPage from "../../pages/PaymentPage/PredefinedCheckoutPage";
import PartnerAdd from "../../pages/PartnerAdd/PartnerAdd";
import SettingAdmin from "../../pages/SettingAdmin/SettingAdmin";
import PartnerSignin from "../../pages/PartnerSignin/PartnerSignin";
import SendSubPartner from "../../pages/SendSubPartner/SendSubPartner";
import PrivacyPartner from "../../pages/PrivacyPartner/PrivacyPartner";

const RoutesEntryApp = () => {
  const { user, setUser } = useContext(authContext);

  useEffect(() => {
    const lToken = window.localStorage.getItem("token");
    const lRefresh = window.localStorage.getItem("refresh");
    if (!user && lToken && lRefresh) {
      me().then((res) => {
        setUser(res.data);
      });
    }
  }, [user, setUser]);

  return (
    <Routes>
      <Route element={<NavBar />}>
        <Route path="/" element={<Home />} />
        <Route path={PATHS.associationList} element={<AssociationList />} />

        <Route path={PATHS.associationList}>
          <Route
            path={PATHS.associationProfile + "/:id?"}
            element={<AssociationEdit />}
          />
        </Route>

        <Route path={PATHS.associationLogin} element={<AssociationLogin />} />
        <Route path={PATHS.associationSignin} element={<AssociationSignin />} />
        <Route path={PATHS.socioLogin} element={<SocioLogin />} />
        <Route path={PATHS.partnerSignin} element={<PartnerSignin />} />
        <Route path={PATHS.adminLogin} element={<AdminLogin />} />
        <Route
          path={PATHS.resetPassword + "/:id?"}
          element={<ResetPassword />}
        />

        <Route
          path={PATHS.sendSubPartner + "/:idSub"}
          element={<SendSubPartner />}
        />

        <Route
          path={PATHS.privacyPartner + "/:idSub"}
          element={<PrivacyPartner />}
        />

        <Route path={PATHS.paymentPage + "/:id"} element={<PaymentPage />} />
        {user && (
          <>
            <Route
              path={"/checkoutPredefined"}
              element={<PredefinedCheckoutPage />}
            />

            {user.role === RoleNames.ADMIN && (
              <>
                <Route
                  path={PATHS.adminAssociationList}
                  element={<AdminAssociationList />}
                />

                <Route path={PATHS.settingsAdmin} element={<SettingAdmin />} />

                <Route
                  path={PATHS.adminAssociationProfile + "/:id?"}
                  element={<AssociationEdit />}
                />
                <Route
                  path={PATHS.adminPaymentsList + "/:id?"}
                  element={<AdminPaymentsList />}
                />
                <Route
                  path={PATHS.ticketList + "/:id?"}
                  element={<TicketList />}
                />
                <Route path={PATHS.ticketList + "/:id"}>
                  <Route
                    path={PATHS.ticketChat + "/:idTicket?"}
                    element={<TicketChat />}
                  />
                </Route>
              </>
            )}
            {user.role === RoleNames.ASSOCIATION && (
              <>
                <Route
                  path={PATHS.associationProfile + "/:id?"}
                  element={<AssociationEdit />}
                />
                <Route path={PATHS.partnerList} element={<PartnerList />} />
                <Route
                  path={PATHS.partnerEdit + "/:id?"}
                  element={<PartnerEdit />}
                />
                <Route path={PATHS.partnerAdd} element={<PartnerAdd />} />
                <Route
                  path={PATHS.ticketListAssociation + "/:id"}
                  element={<TicketList />}
                />
                <Route path={PATHS.ticketListAssociation + "/:id"}>
                  <Route
                    path={PATHS.ticketChat + "/:idTicket?"}
                    element={<TicketChat />}
                  />
                </Route>
              </>
            )}
            {user.role === RoleNames.PARTNER && (
              <>
                <Route
                  path={PATHS.partnerProfile + "/:id?"}
                  element={<PartnerEdit />}
                />
              </>
            )}
          </>
        )}
      </Route>
    </Routes>
  );
};

export default RoutesEntryApp;
