import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Snackbar,
  Alert,IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
//style
import style from "./modalPaymentSubscriptionStyle.module.scss";
import { getPrice } from "../../models/association";
import { authContext } from "../../context/auth";
import { RoleNames } from "../../utils/roles";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (sub: Payment) => void;
}

type Payment = {
  status: "new" | "active" | "pending" | "not active";
  startDate: string;
  endDate: string;
};

const today = new Date().toISOString().split("T")[0];

function ModalPaymentSubscription({ open, onClose, onConfirm }: Props) {
  const { user } = useContext(authContext);
  const [price, setPrice] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  useEffect(() => {
    user?.role === RoleNames.ASSOCIATION &&
      getPrice().then((response) => {
        let year = new Date().getFullYear();

        const startDate = `${year}-${response.data.start_date.split("/")[1]}-${
          response.data.start_date.split("/")[0]
        }`;
        //console.log(response.data);

        if (
          response.data.end_date.split("/")[1] <
          response.data.start_date.split("/")[1]
        ) {
          year = year + 1;
        }
        const endDate = `${year}-${response.data.end_date.split("/")[1]}-${
          response.data.end_date.split("/")[0]
        }`;

        setPrice(response.data.price);
        setStartDate(startDate < today ? today : startDate);
        setEndDate(endDate);
      });
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div
        className={style.modal}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Typography variant="h4">Nuova Iscrizione</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <TextField
            onChange={(e) => setStartDate(e.target.value)}
            value={startDate}
            label="Data di inizio"
            type="date"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: today, // Imposta la data minima come oggi
            }}
          />
          <TextField
            onChange={(e) => setEndDate(e.target.value)}
            value={endDate}
            label="Data di fine"
            type="date"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: startDate,
            }}
          />
        </div>

        <div>
          <Typography>
            <b>Totale:</b> {price}&euro;
          </Typography>
        </div>
        <Button
          variant="outlined"
          color="success"
          onClick={
            () =>{
              onConfirm({
                status: "active",
                startDate: startDate,
                endDate: endDate,
              })
            }

            // navigate("/checkoutPredefined", {
            //   state: {
            //     products: [{ id: "0", price: 20, quantity: 2, title: "test" }],
            //     currency: "eur",
            //   },
            // })
          }
        >
          Crea Iscrizione
        </Button>
      </div>
    </Modal>
  );
}

export default ModalPaymentSubscription;
