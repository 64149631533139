import api from "./api";

export type Package = {
  id: string;
  name: string;
  min_partner: number;
  max_partner: number;
  montly_cost: number;
  annual_cost: number;
};

export type PaymentPackage = {
  associationId: string;
  packageId: string;
  type: string;
  discount: string;
};

export const getAllPackage = () => {
  return api.get("/payment/package");
};

export const addNewPackage = (paymentPackage: PaymentPackage) => {
  return api.post("/payment", paymentPackage);
};
