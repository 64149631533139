export const downloadFile = (data: any, nameFile: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: "application/octet-stream",
    })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", nameFile);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
