import React, { useContext, useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import style from "./tablePartnerServizi.module.scss";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import { StyledTableRow } from "../TableCustom/TableCustom";
import { useParams } from "react-router-dom";
import { authContext } from "../../context/auth";
import { RoleNames } from "../../utils/roles";
import {
  getServicePayment,
  postServicePayment,
} from "../../models/serviceAssociation";
import ModalPaymentServizio from "../ModalPaymentServizio/ModalPaymentServizio";
import { getReceipt } from "../../models/partner";
import { downloadFile } from "../../utils/downloadFile";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";
import { stringDate } from "../../utils/stringDate";

let maxRow = 10;

interface Props {
  statusNew: boolean;
}

function TablePartnerServizi({ statusNew }: Props) {
  const { id } = useParams();
  const { user } = useContext(authContext);

  const [addRow, setAddRow] = useState<boolean>(false);

  const [payment, setPayment] = useState<any[]>([]);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  useEffect(() => {
    getData();
  }, []);

  function getData(take = 10, page = 1) {
    getServicePayment(user?.id!, id!, { page: page, take: take }).then(
      (response) => {
        maxRow = response.data.total;
        const data: any[] = [];
        response.data.data.forEach((element: any) => {
          data.push({
            id: element.id,
            service: element.service.name,
            price: element.price,
            start_validity: stringDate(element.start_date, true),
            end_validity: stringDate(element.end_date, true),
            num_receipt: element.num_receipt,
          });
        });
        setPayment(data);
      }
    );
  }

  return (
    <div className={style.container}>
      <section>
        <Typography variant="h5"> Storico Servizi</Typography>
        {user?.role === RoleNames.ASSOCIATION && !statusNew && (
          <IconButton size="large" onClick={() => setAddRow(true)}>
            <AddCircleOutlinedIcon fontSize="large" color="success" />
          </IconButton>
        )}
      </section>
      <TableContainer component={Paper}>
        <Table className={style.table}>
          <TableHead>
            <TableRow className={style.rowHead}>
              <TableCell size="small" className={style.head}>
                Servizio
              </TableCell>
              <TableCell size="small" className={style.head}>
                Inizio Validità
              </TableCell>
              <TableCell size="small" className={style.head}>
                Fine Validità
              </TableCell>
              <TableCell size="small" className={style.head}>
                Prezzo
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payment.map((row: any) => {
              return (
                <StyledTableRow key={row.id}>
                  <TableCell>{row.service}</TableCell>
                  <TableCell>{row.start_validity}</TableCell>
                  <TableCell>{row.end_validity}</TableCell>
                  <TableCell>{row.price}€</TableCell>

                  <TableCell>
                    <IconButton
                      onClick={() =>
                        getReceipt("service", row.id).then((response) => {
                          //console.log(response);
                          downloadFile(
                            response.data,
                            "fattura_" + row.num_receipt + ".pdf"
                          );
                        })
                      }
                    >
                      <RequestQuoteOutlinedIcon />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { value: maxRow, label: "All" },
                ]}
                count={maxRow ? maxRow : -1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={(event, newPage: number) => {
                  setPage(newPage);
                  getData(rowsPerPage, newPage + 1);
                }}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                  getData(parseInt(event.target.value, 10), 1);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ModalPaymentServizio
        onConfirm={(service) => {
          postServicePayment({
            associationId: user?.id!,
            partnerId: id!,
            serviceId: service.serviceId,
            start_date: service.start_validity,
            end_date: service.end_validity,
            price: service.price,
          }).then(() => {
            setAddRow(false);
            getData();
          });
        }}
        onClose={() => setAddRow(false)}
        open={addRow}
      />
    </div>
  );
}

export default TablePartnerServizi;
