import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AuthProvider } from "./context/auth";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

import { Button } from "@mui/material";

import { AxiosComponent } from "./models/api";
import { ErrorBoundary } from "react-error-boundary";
import RoutesEntryApp from "./components/RoutesEntryApp/RoutesEntryApp";

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <div role="alert" style={{ marginTop: "60px", marginLeft: "20px" }}>
      <p>Qualcosa è andato storto</p>
      <Button onClick={resetErrorBoundary} variant="outlined">
        Torna indietro
      </Button>
      <small>
        <pre>{error.message}</pre>
        <pre>{error.stack}</pre>
      </small>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <AxiosComponent />
          <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => (window.location.href = "/")}
            >
              <RoutesEntryApp />
            </ErrorBoundary>
          </Box>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
