import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PATHS from "../../utils/paths";
//component  function
import CardAuth from "../../components/CardAuth/CardAuth";
import InputCustom from "../../components/InputCustom/InputCustom";
//mui
import { Button, TextField, Typography } from "@mui/material";
//api
import { me } from "../../models/user";
import { loginSocio } from "../../models/auth";
import { authContext } from "../../context/auth";
import BackgroundLine from "../../components/BackgroundLine/BackgroundLine";

function SocioLogin() {
  const navigate = useNavigate();
  const { setToken, setRefresh, setUser, user } = useContext(authContext);

  const [CF, setCF] = useState<string>("");
  const [password, setpassword] = useState<string>("");

  useEffect(() => {
    if (user) navigate(PATHS.associationList);
  }, []);

  return (
    <div className={"pageContainer pageCenter"}>
      <div style={{ marginBottom: "30px" }}>
        <CardAuth
          title={"Login Socio"}
          buttons={
            <Button
              variant="contained"
              onClick={() =>
                loginSocio({ user: CF, pass: password })
                  .then((res: any) => {
                    if (res.status === 201) {
                      const json = res.data;
                      setToken(json.authorization);
                      setRefresh(json.refresh);
                    }
                  })
                  .then(me)
                  .then((res) => {
                    setUser(res.data);
                    navigate(PATHS.partnerProfile + "/" + res.data.id);
                  })
              }
            >
              Login
            </Button>
          }
        >
          <>
            <TextField
              size="small"
              value={CF}
              label="codice fiscale"
              onChange={(e) => setCF(e.target.value)}
            />
            <TextField
              size="small"
              value={password}
              type="password"
              label="password"
              onChange={(e) => setpassword(e.target.value)}
            />
            <section>
              <Typography
                variant="overline"
                component={Link}
                to={PATHS.resetPassword}
              >
                Hai dimenticato la password?
              </Typography>
            </section>
          </>
        </CardAuth>
      </div>
      <BackgroundLine />
      {/* <div className={"background"}></div> */}
    </div>
  );
}

export default SocioLogin;
