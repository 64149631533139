import React, { useEffect, useState } from "react";
import { createPaymentIntent } from "../../models/payment";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useParams } from "react-router-dom";

function PaymentPage() {
  const [clientSecret, setClientSecret] = useState<string>("");
  const [stripePromise, setStripePromise] = useState<any>();
  const [price, setPrice] = useState<number>(0);

  const { id } = useParams();

  useEffect(() => {
    createPaymentIntent(id!).then((response) => {
      setClientSecret(response.data.clientSecret);
      setStripePromise(loadStripe(response.data.public_key));
      setPrice(response.data.paymentIntentAmount);
    });
  }, []);

  const appearance: any = {
    theme: "stripe",
  };
  const options: any = {
    clientSecret,
    appearance,
  };

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm price={price} />
        </Elements>
      )}
    </div>
  );
}

export default PaymentPage;
