import api from "./api";
import { Pagination } from "./association";
import { Subscription } from "./subscription";

export type Partner = {
  internalId?: number | JSX.Element;
  id?: string;
  status?: string;
  email: string;
  phone_number: string;
  end_validity?: string;
  password?: string;
  cittadinanza?: string;
  date_birth?: string;
  tax_id_code?: string;
  city: string;
  province: string;
  address: string;
  cap: string;
  subscription?: Subscription;
  internal_id_duplicate?: boolean;

  scadenza_c_medico?: string;
  scadenza_c_fiscale?: string;
  scadenza_c_identita?: string;

  name: string;
  surname: string;
  tutor?: {
    email: string;
    name: string;
    cittadinanza: string;
    surname: string;
    tax_id_code: string;
    city: string;
    province: string;
    address: string;
    cap: string;
    phone_number: string;
    relationship: string;
  };
};

export type CheckPartner = {
  tax_id_code: string;
  email: string;
};

export type EmailPartner = {
  associationId: string;
  associationName: string;
  partnerEmail: string;
  tax_id_code: string;
};

export const getAllPartnerByAssociation = (
  pagination: Pagination,
  idAssociation: string
) => {
  return api.get("/partner", {
    params: { take: pagination.take, page: pagination.page, q: pagination.q },
  });
};
export const getPartnerById = (id: string) => {
  return api.get("/partner/" + id);
};

export const updatePartnerById = (id: string, partner: Partner) => {
  return api.put("/partner/" + id, partner);
};
export const changeInternalIdPartnerById = (
  id: string,
  partner: { internal_id: number }
) => {
  return api.put("/partner/" + id, partner);
};

export const checkPartner = (partner: CheckPartner) => {
  return api.post("/partner/check", partner);
};

export const sendEmailPartner = (partner: EmailPartner) => {
  return api.post("/partner/email", partner);
};

export const createPartner = (partner: Partner) => {
  return api.post("/partner", partner);
};

export const registerPartner = (partner: Partner) => {
  return api.post("/partner/register", partner);
};

export const getReceipt = (type: "subscription" | "service", id: string) => {
  return api.get("/payment/create-receipt", {
    responseType: "blob",
    params: { id: id, type: type },
  });
};

export const downloadPartner = (template: boolean) => {
  return api.get("/partner/download", {
    params: { template: template },
    responseType: "blob",
  });
};

export const uploadPartner = (file: FormData) => {
  return api.post("/partner/upload", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
