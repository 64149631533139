import React, { createContext, useState, useEffect, Context } from "react";

import api from "../models/api";
import { UserDTO } from "../models/user";

interface AuthContext {
  token?: string | null;
  refresh?: string | null;
  setToken: Function;
  setRefresh: Function;
  user: UserDTO | null;
  setUser: Function;
  logout: () => void;
}

const defaultState = {
  setToken: (val: string) => {},
  setRefresh: (val: string) => {},
  user: null,
  setUser: (val: string) => {},
  logout: () => {},
};

export const authContext: Context<AuthContext> =
  createContext<AuthContext>(defaultState);

export function AuthProvider({ children }: any) {
  const [token, setToken]: [string | null, Function] = useState(null);
  const [refresh, setRefresh]: [string | null, Function] = useState(null);
  const [user, setUser]: [UserDTO | null, Function] = useState(null);

  const logout = () => {
    setToken(null);
    window.localStorage.removeItem("token");
    setRefresh(null);
    window.localStorage.removeItem("refresh");
    api.defaults.headers.common["Authorization"] = "";
    window.location.reload();
    window.location.href = "/";
  };

  useEffect(() => {
    const lToken = window.localStorage.getItem("token");
    const lRefresh = window.localStorage.getItem("refresh");
    setToken(lToken || "");
    setRefresh(lRefresh || "");
    api.defaults.headers.common["Authorization"] = `Bearer ${lToken}`;
  }, []);

  // Only continue rendering if the authorization is initialized
  if (token !== null) {
    return (
      <authContext.Provider
        value={{
          token,
          refresh,
          setToken,
          setRefresh,
          user,
          setUser,
          logout,
        }}
      >
        {children}
      </authContext.Provider>
    );
  }
  return <></>;
}
