import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";

import style from "./partnerSigninStyle.module.scss";
import InputUploadFile from "../../components/InputUploadFile/InputUploadFile";
import { createPartner, registerPartner } from "../../models/partner";
import { useLocation, useNavigate } from "react-router-dom";
import InputCustom from "../../components/InputCustom/InputCustom";
import { deleteFile, sendFile } from "../../models/file";
import { fileTypes } from "../../utils/fileType";
import InputPassword from "../../components/InputPassword/InputPassword";
import useIsUnderage from "../../utils/useIsUnderage";
import CloseIcon from "@mui/icons-material/Close";

function PartnerSignin() {
  const navigate = useNavigate();
  const location = useLocation();

  const [cittadinanza, setCittadinanza] = useState<string>("");

  const [error, setError] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");
  const [cap, setCap] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [taxId, setTaxId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [ripetiPassword, setRipetiPassword] = useState<string>("");

  const [scadenza_c_medico, setScadenza_c_medico] = useState<string>("");
  const [scadenza_c_fiscale, setScadenza_c_fiscale] = useState<string>("");
  const [scadenza_c_identita, setScadenza_c_identita] = useState<string>("");

  const [dateBirth, setDateBirth] = useState<string>("");

  const [identita, setIdentita] = useState<any>();
  const [medico, setMedico] = useState<any>();
  const [fiscale, setFiscale] = useState<any>();

  const [errorPassord, setErrorPassword] = useState<boolean>(false);

  const [tutor, setTutor] = useState<boolean>(false);
  const [emailTutor, setEmailTutor] = useState<string>("");
  const [nameTutor, setNameTutor] = useState<string>("");
  const [cittadinanzaTutor, setCittadinanzaTutor] = useState<string>("");
  const [surnameTutor, setSurnameTutor] = useState<string>("");
  const [taxIdTutor, setTaxIdTutor] = useState<string>("");
  const [cityTutor, setCityTutor] = useState<string>("");
  const [provinceTutor, setProvinceTutor] = useState<string>("");
  const [addressTutor, setAddressTutor] = useState<string>("");
  const [capTutor, setCapTutor] = useState<string>("");
  const [phoneTutor, setPhoneTutor] = useState<string>("");
  //TODO: aggiungere
  const [relationship, setRelationship] = useState<string>("");

  function createPartnerFunction() {
    if (password !== ripetiPassword) {
      setErrorPassword(true);
      return;
    }
    if (
      !cittadinanza ||
      !dateBirth ||
      !address ||
      !cap ||
      !city ||
      !email ||
      !province ||
      !taxId ||
      !password ||
      !name ||
      !surname
    ) {
      setError(true);
      return;
    }
    let partner: any = {
      cittadinanza: cittadinanza,
      date_birth: dateBirth,
      address: address,
      cap: cap,
      city: city,
      email: email,
      scadenza_c_fiscale: scadenza_c_fiscale,
      scadenza_c_identita: scadenza_c_identita,
      scadenza_c_medico: scadenza_c_medico,
      phone_number: phone,
      province: province,
      tax_id_code: taxId,
      password: password,
      name: name,
      surname: surname,
      subscription: {
        associationId: location.state?.idAssociation,
        status: "new",
      },
    };
    if (tutor)
      partner = {
        ...partner,
        tutor: {
          email: emailTutor,
          name: nameTutor,
          cittadinanza: cittadinanzaTutor,
          surname: surnameTutor,
          tax_id_code: taxIdTutor,
          city: cityTutor,
          province: provinceTutor,
          address: addressTutor,
          cap: capTutor,
          phone_number: phoneTutor,
          relationship: "",
        },
      };

    registerPartner(partner).then((response) => {
      if (identita)
        sendFile(identita, "partner", response.data.id, fileTypes.indentita);
      if (medico)
        sendFile(medico, "partner", response.data.id, fileTypes.medico);
      if (fiscale)
        sendFile(fiscale, "partner", response.data.id, fileTypes.fiscale);
      navigate(-1);
    });
  }

  useEffect(() => {
    tutorOn(dateBirth);
  }, [dateBirth]);

  function tutorOn(data: string) {
    const dataDiNascita = new Date(data);

    const dataCorrente = new Date();

    let age = dataCorrente.getFullYear() - dataDiNascita.getFullYear();

    // Verifica se il compleanno è già avvenuto quest'anno o no
    const meseCorrente = dataCorrente.getMonth() + 1;
    const giornoCorrente = dataCorrente.getDate();

    if (
      meseCorrente < dataDiNascita.getMonth() ||
      (meseCorrente === dataDiNascita.getMonth() &&
        giornoCorrente < dataDiNascita.getDate())
    ) {
      age--;
    }

    setTutor(age < 18);
  }

  return (
    <div className={"pageContainer " + style.content}>
      <Button
        onClick={createPartnerFunction}
        variant="contained"
        style={{
          backgroundColor: style.secondaryColor,
          alignSelf: "flex-end",
          marginTop: "50px",
        }}
      >
        Iscriviti
      </Button>

      <div style={{ display: "flex", gap: "30px", minWidth: "60%" }}>
        <div className={"cardInfo " + style.info}>
          <section>
            <Typography variant="h5">Informazioni Socio</Typography>
          </section>
          <div className={style.contentInfo}>
            <section>
              <InputCustom
                error={!name && error}
                value={name}
                onChange={(value) => setName(value)}
                variant="standard"
                label="Nome"
              />
              <InputCustom
                error={!surname && error}
                value={surname}
                onChange={(value) => setSurname(value)}
                variant="standard"
                label="Cognome"
              />
            </section>
            <section>
              <InputCustom
                error={!email && error}
                value={email}
                onChange={(value) => setEmail(value)}
                variant="standard"
                label="Email"
              />
              <InputCustom
                type="number"
                value={phone}
                onChange={(value) => setPhone(value)}
                variant="standard"
                label="Telefono"
              />
            </section>
            <section>
              <InputCustom
                error={!taxId && error}
                value={taxId}
                onChange={(value) => setTaxId(value)}
                variant="standard"
                label="Codice Fiscale"
              />
            </section>

            <section>
              <InputPassword
                error={errorPassord || (!password && error)}
                value={password}
                onChange={(value) => {
                  errorPassord && setErrorPassword(false);
                  setPassword(value);
                }}
                variant="standard"
                label="Password"
              />
              <InputPassword
                error={errorPassord}
                value={ripetiPassword}
                onChange={(value) => {
                  errorPassord && setErrorPassword(false);
                  setRipetiPassword(value);
                }}
                variant="standard"
                label="Ripeti Password"
              />
            </section>
          </div>
        </div>
        <div className="cardInfo">
          <section>
            <Typography variant="h5">Informazioni Località</Typography>
          </section>
          <div style={{ minWidth: "250px" }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
              <InputCustom
                error={!cap && error}
                value={cap}
                onChange={(value) => {
                  if (value.match("[^0-9]")) return;
                  setCap(value);
                }}
                variant="standard"
                label="CAP"
              />
              <InputCustom
                error={!province && error}
                value={province}
                onChange={(value) => setProvince(value)}
                variant="standard"
                label="Provincia"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
              <InputCustom
                error={!dateBirth && error}
                value={dateBirth}
                onChange={(value) => {
                  //console.log(value);
                  setDateBirth(value);
                }}
                variant="standard"
                label="Data di nascita"
                type="date"
              />
              <InputCustom
                error={!city && error}
                value={city}
                onChange={(value) => setCity(value)}
                variant="standard"
                label="Città"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
              <InputCustom
                error={!address && error}
                value={address}
                onChange={(value) => setAddress(value)}
                variant="standard"
                label="Indirizzo"
              />

              <InputCustom
                error={!cittadinanza && error}
                value={cittadinanza}
                onChange={(value) => setCittadinanza(value)}
                variant="standard"
                label="Cittadinanza"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.form}>
        <div className={style.form}>
          <div className="cardInfo">
            <section>
              <Typography variant="h5">Documenti</Typography>
            </section>
            <div className={style.document}>
              <div className={style.field}>
                <InputUploadFile
                  file={(file) => setIdentita(file)}
                  value={identita}
                  onDelete={(id) => {
                    deleteFile(id).then(() => setIdentita(undefined));
                  }}
                  label="C.I."
                />
                <TextField
                  onChange={(e) => setScadenza_c_identita(e.target.value)}
                  value={scadenza_c_identita}
                  label="Data scadenza"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </div>
              <div className={style.field}>
                <InputUploadFile
                  file={(file) => setFiscale(file)}
                  value={fiscale}
                  onDelete={(id) => {
                    deleteFile(id).then(() => setFiscale(undefined));
                  }}
                  label="C.F."
                />
                <TextField
                  onChange={(e) => setScadenza_c_fiscale(e.target.value)}
                  value={scadenza_c_fiscale}
                  label="Data scadenza"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </div>
              <div className={style.field}>
                <InputUploadFile
                  file={(file) => setMedico(file)}
                  value={medico}
                  onDelete={(id) => {
                    deleteFile(id).then(() => setMedico(undefined));
                  }}
                  label="Certificato Medico"
                />
                <TextField
                  onChange={(e) => setScadenza_c_medico(e.target.value)}
                  value={scadenza_c_medico}
                  label="Data scadenza"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </div>
            </div>
          </div>

          {tutor && (
            <div className={"cardInfo " + style.info}>
              <section>
                <Typography variant="h5">Informazioni Tutore</Typography>
              </section>
              <div className={style.contentInfo}>
                <section>
                  <InputCustom
                    value={nameTutor}
                    onChange={(value) => setNameTutor(value)}
                    variant="standard"
                    label="Nome"
                  />
                  <InputCustom
                    value={surnameTutor}
                    onChange={(value) => setSurnameTutor(value)}
                    variant="standard"
                    label="Cognome"
                  />
                </section>
                <section>
                  <InputCustom
                    value={emailTutor}
                    onChange={(value) => setEmailTutor(value)}
                    variant="standard"
                    label="Email"
                  />
                  <InputCustom
                    value={phoneTutor}
                    onChange={(value) => setPhoneTutor(value)}
                    variant="standard"
                    label="Telefono"
                  />
                </section>
                <section>
                  <InputCustom
                    value={taxIdTutor}
                    onChange={(value) => setTaxIdTutor(value)}
                    variant="standard"
                    label="Codice Fiscale"
                  />
                </section>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "30px" }}
                >
                  <InputCustom
                    value={capTutor}
                    onChange={(value) => setCapTutor(value)}
                    variant="standard"
                    label="CAP"
                  />
                  <InputCustom
                    value={provinceTutor}
                    onChange={(value) => setProvinceTutor(value)}
                    variant="standard"
                    label="Provincia"
                  />
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "30px" }}
                >
                  <InputCustom
                    value={cityTutor}
                    onChange={(value) => setCityTutor(value)}
                    variant="standard"
                    label="Città"
                  />
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "30px" }}
                >
                  <InputCustom
                    value={addressTutor}
                    onChange={(value) => setAddressTutor(value)}
                    variant="standard"
                    label="Indirizzo"
                  />

                  <InputCustom
                    value={cittadinanzaTutor}
                    onChange={(value) => setCittadinanzaTutor(value)}
                    variant="standard"
                    label="Cittadinanza"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PartnerSignin;
