import React, { useContext, useEffect, useState } from "react";
import style from "./stripeKeyFormStyle.module.scss";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InputCustom from "../InputCustom/InputCustom";
import { sendKeys } from "../../models/payment";
import { authContext } from "../../context/auth";
import { setPriceAPI } from "../../models/association";
import CloseIcon from "@mui/icons-material/Close";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
// @ts-ignore
import samplePDF1 from "../../assets/tutorial.pdf";

const regexPattern = new RegExp("\\d\\d/\\d\\d");

interface Props {
  association: {
    startDate: string | undefined;
    endDate: string | undefined;
    price: string | undefined;
    disabled: boolean | undefined;
  };
  onSave: () => void;
  placeholderKeys: boolean;
}
const placeholderKey =
  "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa";

function StripeKeyForm({ association, onSave, placeholderKeys }: Props) {
  const { id } = useParams();
  const { user } = useContext(authContext);

  const [publicKey, setPublicKey] = useState<string>("");
  const [errorPublicKey, setErrorPublicKey] = useState<boolean>(false);
  const [privateKey, setPrivateKey] = useState<string>("");
  const [errorPrivateKey, setErrorPrivateKey] = useState<boolean>(false);

  const [price, setPrice] = useState<string>("");

  const [typeSub, setTypeSub] = useState<string>("A");
  const [dateStart, setDateStart] = useState<string>("01/01");
  const [dateEnd, setDateEnd] = useState<string>("31/12");

  const [setted, settedPrice] = React.useState(false);

  const [verified, setVerified] = React.useState(false);

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (placeholderKeys) {
      setPublicKey(placeholderKey || privateKey === placeholderKey);
      setPrivateKey(placeholderKey || privateKey === placeholderKey);
    }
  }, [placeholderKeys]);

  useEffect(() => {
    setPrice(association.price ? association.price : "");
    if (association.endDate === "31/12" && association.startDate === "01/01")
      setTypeSub("A");
    else if (association.startDate === "") setTypeSub("I");
    else if (association.startDate) setTypeSub("P");
    else setTypeSub("A");
  }, [association]);

  useEffect(() => {
    if (typeSub === "A") {
      setDateStart("01/01");
      setDateEnd("31/12");
    }
  }, [typeSub]);

  function checkValidityDate() {
    if (regexPattern.test(dateStart) && regexPattern.test(dateEnd)) {
      return true;
    }
    setError(true);
    return false;
  }

  const priceSaved = () => {
    settedPrice(true);
  };

  const closePriceSaved = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    settedPrice(false);
  };
  const priceSnack = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closePriceSaved}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const verifiedPublicKey = async (): Promise<boolean> => {
    if (!publicKey) {
      setErrorPublicKey(true);
      return false;
    }
    if (publicKey === placeholderKey || privateKey === placeholderKey) {
      //le key non sono state cambiate
      return false;
    }
    const stripe = await loadStripe(publicKey);
    const response = await stripe?.createToken("pii", {
      personal_id_number: "123131185",
    });

    if (response?.token !== undefined) {
      return true;
    }
    setErrorPublicKey(true);
    return false;
  };

  const closeVerifiedKey = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setVerified(false);
  };

  const verifySnack = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeVerifiedKey}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={setted}
        autoHideDuration={6000}
        onClose={closePriceSaved}
        message="Prezzo salvato"
        action={priceSnack}
        key={"saved_price"}
      >
        <Alert
          onClose={closePriceSaved}
          severity="success"
          sx={{ width: "100%" }}
        >
          Prezzo slavato!
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={verified}
        autoHideDuration={6000}
        onClose={closeVerifiedKey}
        message="Key verificata e salvata con successo"
        action={verifySnack}
        key={"verify_keys"}
      >
        <Alert
          onClose={closeVerifiedKey}
          severity="success"
          sx={{ width: "100%" }}
        >
          Key verificata e salvata con successo!
        </Alert>
      </Snackbar>

      <div className="cardInfo">
        <section>
          <Typography variant="h5">Sezione Pagamento</Typography>
        </section>
        {id ? (
          <div>
            <div>
              <Typography variant="caption">
                Guarda i passaggi per trovare le tue chiavi di{" "}
                <a download="tutorial_stripe.pdf" href={samplePDF1}>
                  {" "}
                  Stripe
                </a>{" "}
                e inseriscile qui sotto.
              </Typography>
            </div>

            <section className={style.field}>
              <TextField
                disabled={association.disabled}
                error={errorPublicKey}
                type="password"
                value={publicKey}
                onChange={(e) => {
                  setErrorPublicKey(false);
                  setPublicKey(e.target.value);
                }}
                className={style.longInput}
                variant="standard"
                label="Chiave Pubblica"
              />
              <TextField
                disabled={association.disabled}
                error={errorPrivateKey}
                type="password"
                value={privateKey}
                onChange={(e) => {
                  setErrorPrivateKey(false);
                  setPrivateKey(e.target.value);
                }}
                className={style.longInput}
                variant="standard"
                label="Chiave Privata"
              />
              <Button
                disabled={association.disabled}
                onClick={async () => {
                  (await verifiedPublicKey()) &&
                    sendKeys({
                      associationId: user?.id!,
                      public_key: publicKey,
                      private_key: privateKey,
                    })
                      .then(() => {
                        setVerified(true);
                      })
                      .catch(() => {
                        setErrorPrivateKey(true);
                      });
                }}
              >
                Verifica key
              </Button>
            </section>
            <section className={style.field}>
              <div style={{ width: "20%" }}>
                <InputCustom
                  disabled={association.disabled}
                  variant="standard"
                  label="Costo Iscrizione"
                  onChange={(value) => setPrice(value)}
                  value={price}
                />
              </div>

              <FormControl
                disabled={association.disabled}
                variant="standard"
                style={{ minWidth: "195px", marginTop: "-3px" }}
              >
                <InputLabel>Tipo Iscrizione</InputLabel>
                <Select
                  disabled={association.disabled}
                  value={typeSub}
                  label="Tipo Iscrizione"
                  onChange={(e: SelectChangeEvent) =>
                    setTypeSub(e.target.value)
                  }
                >
                  <MenuItem value="A">Anno Solare</MenuItem>
                  <MenuItem value="P">Periodo</MenuItem>
                  <MenuItem value="I">Iscrizione</MenuItem>
                </Select>
              </FormControl>
              <div style={{ width: "60%" }}>
                {typeSub ? (
                  <>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <InputCustom
                        //error={error && checkValidityDate()}
                        variant="standard"
                        placeholder="Giorno/Mese"
                        label={"inizia a"}
                        onChange={(value) => {
                          setDateStart(value);
                        }}
                        value={dateStart}
                        disabled={typeSub === "A" || association.disabled}
                      />
                      <InputCustom
                        variant="standard"
                        placeholder="Giorno/Mese"
                        label="finisce a"
                        onChange={(value) => {
                          setDateEnd(value);
                        }}
                        value={dateEnd}
                        disabled={typeSub === "A" || association.disabled}
                      />
                    </div>
                  </>
                ) : (
                  <div style={{ display: "flex" }}>
                    <InfoIcon fontSize="small" style={{ marginRight: "5px" }} />
                    <Typography>
                      Selezionare la tipologia di iscrizione
                    </Typography>
                  </div>
                )}
              </div>
              <Button
                disabled={association.disabled}
                onClick={() => {
                  if (!checkValidityDate() && typeSub !== "I") {
                    return;
                  }
                  setPriceAPI({
                    associationId: id,
                    end_date: dateEnd,
                    start_date: dateStart,
                    price: parseFloat(price),
                  })
                    .then(() => {
                      priceSaved();
                      onSave();
                    })
                    .catch();
                }}
              >
                Salva
              </Button>
            </section>
          </div>
        ) : (
          <div>
            <Typography className="text">Complata la creazione</Typography>
          </div>
        )}
      </div>
    </>
  );
}

export default StripeKeyForm;
