import React, { useEffect, useState } from "react";
import style from "./ticketListStyle.module.scss";
import TableCustom, { Columns } from "../../components/TableCustom/TableCustom";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import PATHS from "../../utils/paths";
import { Ticket, getAllTicketByAssociation } from "../../models/ticket";
import { stringDate } from "../../utils/stringDate";
import { Pagination } from "../../models/association";

function TicketList() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [columns, setColumns] = useState<Columns[]>([]);
  const [row, setRow] = useState<Ticket[]>([]);

  useEffect(() => {
    setColumns([
      {
        label: "Testo",
        name: "last_message",
      },
      {
        label: "Ultimo Aggiornamento",
        name: "update_date",
      },
      {
        label: "Data di apertura",
        name: "start_date",
      },
      {
        label: "Stato",
        name: "status",
        status: {
          value: [
            { label: "Aperto", name: "open" },
            { label: "Chiuso", name: "close" },
          ],
          green: "open",
          red: "close",
        },
      },
      {
        label: "",
        name: "action",
        action: (row) => (
          <Button
            style={{ width: "80%" }}
            variant="contained"
            size="small"
            onClick={() =>
              navigate(PATHS.ticketChat + "/" + row.id, { state: row })
            }
          >
            <Typography variant="button">Apri</Typography>
          </Button>
        ),
      },
    ]);
    getData();
  }, []);

  function getData(page?: Pagination, filter: string = "") {
    getAllTicketByAssociation(id!, { take: 10, page: 1 }, filter).then(
      (response) => {
        const data: Ticket[] = [];
        response.data.data.forEach((element: any) => {
          data.push({
            id: element.id,
            start_date: stringDate(element.start_date),
            status: element.status,
            update_date: stringDate(element.last_message.date),
            last_message: element.last_message.text,
          });
        });
        setRow(data);
      }
    );
  }

  return (
    <div className={"pageContainer " + style.container}>
      <section className={style.filter}>
        <FormControl className={style.select}>
          <InputLabel>Stato</InputLabel>
          <Select
            label={"Stato"}
            onChange={(e: SelectChangeEvent) =>
              getData({ take: 10, page: 1 }, e.target.value)
            }
          >
            <MenuItem value={""}>Tutti</MenuItem>
            <MenuItem value={"open"}>Aperto</MenuItem>
            <MenuItem value={"close"}>Chiuso</MenuItem>
          </Select>
        </FormControl>
        <div>
          <Button
            onClick={() => navigate(PATHS.ticketChat)}
            variant="contained"
          >
            Nuovo Ticket
          </Button>
        </div>
      </section>
      <TableCustom
        columns={columns}
        rows={row}
        pagination={{
          defaultTake: 10,
          maxRows: 7,
          onChange(take, page) {
            //console.log(take + " " + page);
          },
        }}
      />
    </div>
  );
}

export default TicketList;
