import api from "./api";

export type UserDTO = {
  id: string;
  email: string;
  role: string;
  max_partner?: string;
  status?: string;
};

export const me = () => {
  return api.get("/user/me");
};
