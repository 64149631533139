import React, { useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  styled,
} from "@mui/material";
import style from "./tableCustomStyle.module.scss";
//icon
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
//api
import { changeInternalIdPartnerById } from "../../models/partner";

export type Columns = {
  label: string | JSX.Element;
  name: string;
  status?: {
    value: { name: string | null; label: string }[];
    green?: string;
    red?: string;
    gray?: string | null;
    yellow?: string | null;
  };
  action?: (row: any) => JSX.Element;
};

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    //border: 0,
  },
}));

interface Props {
  refresh?: () => void;
  columns: Columns[];
  rows: any[];
  pagination: {
    maxRows: number;
    defaultTake: 5 | 10 | 25 | -1;
    onChange: (take: number, page: number) => void;
  };
}

function TableCustom({ columns, rows, pagination, refresh }: Props) {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    pagination.defaultTake
  );
  //internal id per il partner
  const [changingInternalId, setChangingInternalId] = useState<{
    id: string;
    internalId: number;
  }>({ id: "", internalId: 0 });

  const internalIdElement = (
    internalId: number,
    id: string,
    internal_id_duplicate: boolean
  ) => {
    return (
      <div>
        {changingInternalId.id !== id ? (
          <Tooltip title="clicca il numero per modificare">
            <span
              style={{
                color: internal_id_duplicate ? "red" : "black",
                cursor: "pointer",
              }}
              onClick={() => {
                setChangingInternalId({ id: id, internalId: internalId });
              }}
            >
              {internalId}
            </span>
          </Tooltip>
        ) : (
          <div style={{ display: "flex" }}>
            <TextField
              size="small"
              style={{ width: "75px" }}
              type="number"
              value={changingInternalId.internalId}
              onChange={(e) =>
                setChangingInternalId({
                  ...changingInternalId,
                  internalId: Number(e.target.value),
                })
              }
            ></TextField>
            <IconButton
              onClick={() => {
                changeInternalIdPartnerById(changingInternalId.id, {
                  internal_id: changingInternalId.internalId,
                }).then(() => {
                  setChangingInternalId({ id: "", internalId: 0 });
                  refresh && refresh();
                });
              }}
            >
              <CheckCircleIcon color="success" />
            </IconButton>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={style.table}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={
                    typeof column.label == "string" ? column.label : column.name
                  }
                  className={`${style.column} ${style.textCat}`}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <StyledTableRow key={row.id}>
                {columns.map((column) => {
                  let styled = "";
                  let text = row[column.name];
                  if (column.status) {
                    if (row[column.name] === column.status.green)
                      styled = style.green;
                    else if (row[column.name] === column.status.gray)
                      styled = style.gray;
                    else if (row[column.name] === column.status.red)
                      styled = style.red;
                    else if (row[column.name] === column.status.yellow)
                      styled = style.yellow;

                    const col = column.status.value.find(
                      (e) => e.name === row[column.name]
                    );
                    text = col?.label;
                  }
                  return (
                    <TableCell
                      id={row.id}
                      key={column.label + row.id}
                      style={{ width: 40 }}
                      align={column.action ? "right" : "left"}
                    >
                      {column.action ? (
                        column.action(row)
                      ) : column.name === "internalId" ? (
                        internalIdElement(
                          text,
                          row.id,
                          row.internal_id_duplicate
                        )
                      ) : (
                        <p className={`${style.textCat} ${styled}`}>{text}</p>
                      )}
                    </TableCell>
                  );
                })}
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { value: pagination.maxRows, label: "All" },
                ]}
                count={pagination.maxRows ? pagination.maxRows : -1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={(event, newPage: number) => {
                  setPage(newPage);
                  pagination.onChange(rowsPerPage, newPage);
                }}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                  pagination.onChange(parseInt(event.target.value, 10), 0);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default TableCustom;
