import api from "./api";

export type EntityId = {
  entityId: string;
};
export type FileType = {
  docType: string;
  id: string;
  mime: string;
  name: string;
  url: string;
};

export const sendFile = (
  file: any,
  entity: "association" | "partner",
  entityId: string,
  docType: string
) => {
  return api.post(
    "/file",
    {
      entity: entity,
      entityId: entityId,
      file: file,
      docType: docType,
    },
    { headers: { "Content-Type": "multipart/form-data" } }
  );
};

export const getFile = (entityId: string) => {
  return api.get("/file/", { params: { entityId: entityId } });
};

export const deleteFile = (id: string) => {
  return api.delete("/file/" + id);
};
