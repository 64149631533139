import React from "react";
//MUI
import { Button, Modal } from "@mui/material";
//style
import "./style.css";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  title?: string;
  children?: JSX.Element;
}

function ModalConfirm({
  open,
  onClose,
  onConfirm,
  onCancel,
  title = "Sei sicuro di voler eliminare questo elemento dalla tabella?",
  children
}: ModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modalConfirm">
        <h3>{title}</h3>
        {children}
        <div className="sectionButtonModal">
          <Button
            color="error"
            variant="contained"
            onClick={() => (onCancel ? onCancel() : onClose())}
          >
            Annulla
          </Button>
          <Button variant="contained" onClick={() => onConfirm()}>
            Conferma
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirm;
