import React, { useContext, useEffect, useState } from "react";

import CardAuth from "../../components/CardAuth/CardAuth";
import { me } from "../../models/user";
import { Button, TextField } from "@mui/material";
import { loginAdmin } from "../../models/auth";
import { authContext } from "../../context/auth";
import { useNavigate } from "react-router-dom";
import PATHS from "../../utils/paths";
import BackgroundLine from "../../components/BackgroundLine/BackgroundLine";

function AdminLogin() {
  const navigate = useNavigate();
  const { setToken, setRefresh, setUser, user } = useContext(authContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (user) navigate(PATHS.adminAssociationList);
  }, []);

  return (
    <div className={"pageContainer pageCenter"}>
      <CardAuth
        title={"Login Admin"}
        buttons={
          <Button
            variant="contained"
            onClick={() => {
              loginAdmin({ user: email, pass: password })
                .then((res: any) => {
                  if (res.status === 201) {
                    const json = res.data;
                    setToken(json.authorization);
                    setRefresh(json.refresh);
                  }
                })
                .then(me)
                .then((res) => {
                  setUser(res.data);
                  navigate(PATHS.adminAssociationList);
                });
            }}
          >
            Login
          </Button>
        }
      >
        <>
          <TextField
            size="small"
            value={email}
            label="email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            size="small"
            value={password}
            type="password"
            label="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </>
      </CardAuth>
      <BackgroundLine />
    </div>
  );
}

export default AdminLogin;
