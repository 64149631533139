import React, { SyntheticEvent, useEffect, useState } from "react";

import style from "./adminAssociationListStyle.module.scss";
import img from "../../assets/sport.svg";
//component function
import TableCustom, { Columns } from "../../components/TableCustom/TableCustom";
//mui
import { Button, IconButton, TextField } from "@mui/material";
//icons
import EuroIcon from "@mui/icons-material/Euro";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import ForumIcon from "@mui/icons-material/Forum";
import { useNavigate } from "react-router-dom";
import PATHS from "../../utils/paths";
import { Association, getAllAdminAssociations } from "../../models/association";
import { stringDate } from "../../utils/stringDate";

function AdminAssociationList() {
  const navigate = useNavigate();

  const [association, setAssociation] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<number>(0);
  const [columns, setColumns] = useState<Columns[]>([]);

  const [pagination, setPagination] = useState<{ take: number; page: number }>({
    take: 10,
    page: 1,
  });

  useEffect(() => {
    setColumns([
      { name: "name", label: "Denominazione" },
      { name: "start_date", label: "Data Iscrizione" },
      { name: "package_name", label: "Pacchetto" },
      {
        name: "status",
        label: "Stato",
        status: {
          value: [
            { label: "Approvata", name: "active" },
            { label: "Non attivo", name: "not active" },
            { label: "Nuovo", name: null },
            { label: "Pending", name: "pending" },
          ],
          green: "active",
          yellow: "pending",
          red: "not active",
          gray: null,
        },
      },
      {
        name: "actions",
        label: "",
        action: (row: any) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              onClick={() => navigate(PATHS.adminPaymentsList + "/" + row.id)}
            >
              <EuroIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate(PATHS.associationProfile + "/" + row.id)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => navigate(PATHS.ticketList + "/" + row.id)}
            >
              <ForumIcon />
            </IconButton>
          </div>
        ),
      },
    ]);
    getData(1, 10);
  }, []);

  function getData(page: number, take: number, search = "") {
    getAllAdminAssociations({ take: take, page: page }, search).then(
      (response) => {
        const data: Association[] = [];

        response.data.data.forEach((element: Association) => {
          data.push({
            address: element.address,
            cap: element.cap,
            city: element.city,
            email: element.email,
            logo: img,
            nameAnagrafica: element.nameAnagrafica,
            surnameAnagrafica: element.surnameAnagrafica,
            name: element.name,
            iban: element.iban,
            phone_number: element.phone_number,
            province: element.province,
            start_date: stringDate(element.start_date),
            vat_number: element.vat_number,
            id: element.id,
            package_name: element.package_name,
            status: element.status,
          });
        });
        setAssociation(data);
        setTotalRow(response.data.total);
      }
    );
  }

  return (
    <div className={"pageContainer " + style.container}>
      <section className={style.filter}>
        <p></p>
        {/* <FormControl className={style.select}>
          <InputLabel>Stato</InputLabel>
          <Select
            label={"Stato"}
            onChange={(e: SelectChangeEvent) =>
              getData(1, pagination.take, e.target.value)
            }
          >
            <MenuItem value={""}>Tutti</MenuItem>
            <MenuItem value={"pending"}>Pending</MenuItem>
            <MenuItem value={"active"}>Attivi</MenuItem>
            <MenuItem value={"not active'"}>Non attivi</MenuItem>
          </Select>
        </FormControl> */}
        <TextField
          onChange={(e) => getData(1, pagination.take, e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton edge={"end"}>
                <SearchIcon />
              </IconButton>
            ),
          }}
        />
      </section>
      <Button
        className={style.button}
        color="success"
        variant="contained"
        onClick={() => navigate(PATHS.associationProfile)}
      >
        Nuova Associazione
      </Button>
      <TableCustom
        columns={columns}
        rows={association}
        pagination={{
          defaultTake: 10,
          maxRows: totalRow,
          onChange(take, page) {
            setPagination({ take: take, page: page });
            getData(page + 1, take);
          },
        }}
      />
      <div className="background"></div>
    </div>
  );
}

export default AdminAssociationList;
