import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authContext } from "../../context/auth";
//style
import style from "./tableServiceStyle.module.scss";
//mui
import {
  Button,
  Chip,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
//api
import {
  Service,
  createServiceById,
  deleteServiceById,
  getServiceById,
  updateServiceById,
} from "../../models/serviceAssociation";
//utils
import { RoleNames } from "../../utils/roles";
//icon
import InfoIcon from "@mui/icons-material/Info";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";

interface Props {
  association: string;
  disabled: boolean;
}

function TableService({ association, disabled }: Props) {
  const { id } = useParams();
  const { user } = useContext(authContext);
  const [service, setService] = useState<Service[]>([]);
  const [editRow, setEditRow] = useState<{ id: string; name: string }>({
    id: "",
    name: "",
  });

  const [addRow, setAddRow] = useState<boolean>(false);
  const [newService, setNewService] = useState<string>("");

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getServiceById(association).then((response) => {
      const data: Service[] = [];

      response.data.forEach((element: Service) => {
        data.push({
          id: element.id,
          name: element.name,
        });
      });
      setService(data);
    });
  }
  function createService() {
    createServiceById({
      associationId: association,
      name: newService,
    }).then(() => {
      setAddRow(false);
      getData();
    });
  }

  function updateService() {
    setEditRow({
      id: "",
      name: "",
    });
    updateServiceById(editRow.id, editRow.name).then(() => setAddRow(false));
  }

  return (
    <div className={style.container + " cardInfo"}>
      <section
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {addRow ? (
          <TextField
            onChange={(e) => setNewService(e.target.value)}
            size="small"
          />
        ) : (
          <div>
            <Typography variant="h5">
              Servizi
              <Tooltip title="Aggiungere un servizio alla volta">
                <InfoIcon fontSize="small" />
              </Tooltip>
            </Typography>
          </div>
        )}
        {(user?.id === id || user?.role === RoleNames.ADMIN) &&
          !disabled &&
          id && (
            <>
              {addRow ? (
                <Button variant="contained" onClick={createService}>
                  Salva
                </Button>
              ) : (
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setAddRow(true)}
                >
                  <AddCircleOutlinedIcon color="success" />
                </IconButton>
              )}
            </>
          )}
      </section>
      <div className={style.service}>
        {service.length > 0 ? (
          service.map((element) => {
            return (
              <Chip
                label={element.name}
                onDelete={
                  (user?.id === id || user?.role === RoleNames.ADMIN) &&
                  !disabled
                    ? () => {
                        deleteServiceById(element.id!).then(() => {
                          getData();
                        });
                      }
                    : undefined
                }
              />
            );
          })
        ) : id ? (
          <section>
            <Typography>Nessun Servizio disponibile</Typography>
          </section>
        ) : (
          <section>
            <Typography>Completa la creazione</Typography>
          </section>
        )}
      </div>
    </div>
  );
}

export default TableService;
