import React from "react";

import style from "./cardAuthStyle.module.scss";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";

interface Props {
  title: string;
  children: JSX.Element;
  buttons: JSX.Element;
}

function CardAuth({ children, title, buttons }: Props) {
  return (
    <Card elevation={10} className={style.card}>
      <div>
        <CardHeader title={title} className={style.header} variant={"h5"} />
        <CardContent className={style.content}>{children}</CardContent>
      </div>
      <CardActions className={style.actions}>{buttons}</CardActions>
    </Card>
  );
}

export default CardAuth;
