import api from "./api";
import { Type } from "./type";
export type Pagination = {
  take: number;
  page: number;
  q?: string;
};

export type Association = {
  address: string;
  city: string;
  province: string;
  name: string;
  iban: string;
  nameAnagrafica?: string;
  presidente?: string;
  surnameAnagrafica?: string;
  cap: string;
  email: string;
  phone_number: string;
  associationAdmins?: string;
  vat_number: string;
  logo?: string;
  start_date?: string;
  end_date?: string;
  password?: string;
  package_name?: string;
  id?: string;
  status?: boolean;
  typeId?: string;
  type?: Type;
  price?: Price;
  active?: boolean;
  keysSaved?: boolean;
};

export type Price = {
  associationId: string;
  price: number;
  start_date: string;
  end_date: string;
};

export const getAllAssociations = (
  pagination: Pagination,
  search: string = ""
) => {
  return api.get("/association", {
    params: { take: pagination.take, page: pagination.page, q: search },
  });
};
export const getAllAdminAssociations = (
  pagination: Pagination,
  search: string = ""
) => {
  return api.get("/user/associations", {
    params: { take: pagination.take, page: pagination.page, q: search },
  });
};

export const getAssociationInfo = (idAssociation: string) => {
  return api.get("/association/" + idAssociation);
};

export const updateAssociationInfo = (
  idAssociation: string,
  association: Association
) => {
  return api.put("/association/" + idAssociation, association);
};

export const updateAssociationAdmins = (
  idAssociation: string,
  associationAdmins: string | null
) => {
  return api.put("/association/" + idAssociation, {
    associationAdmins: associationAdmins,
  });
};

export const createAssociationInfo = (association: Association) => {
  return api.post("/association/", association);
};

export const disableAssociation = (id: string) => {
  return api.put("/association/disable-association", { id: id });
};

//PRICe
export const getPrice = () => {
  return api.get("/association/price");
};

export const setPriceAPI = (price: Price) => {
  return api.post("/association/price", price);
};

//reset PAssword
export const sendNotificationPassword = (emailTaxId: string) => {
  return api.get("/auth/send-notification-reset-password", {
    params: { emailTaxId: emailTaxId },
  });
};

export const changePassword = (id: string, passowrd: string) => {
  return api.post("/auth/reset-password", {
    params: { passowrd: passowrd, id: id },
  });
};
