import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface Props {
  size?: "medium" | "small";
  variant?: "filled" | "outlined" | "standard";
  label?: string;
  value: string;
  onChange: (value: string) => void;
  error?: boolean;
}

function InputPassword({
  size = "small",
  variant = "outlined",
  label = "Password",
  value,
  onChange,
  error = false,
}: Props) {
  const [showPass, setShowPass] = useState<boolean>(false);

  return (
    <TextField
      error={error}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      variant={variant}
      size={size}
      type={showPass ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPass(!showPass)}
              //onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label}
    />
  );
}

export default InputPassword;
