import React, { ChangeEvent, useState } from "react";
import { Button, IconButton, Link, TextField, Typography,
  Snackbar, Alert} from "@mui/material";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { FileType, deleteFile } from "../../models/file";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { Alarm } from "@mui/icons-material";

type File = { name: string; type: string; base64: string };
var _URL = window.URL || window.webkitURL;

interface Props {
  name?: string;
  label: string;
  //file: (file: File) => void;
  disabled?: boolean;
  file: (file: any) => void;
  value?: FileType;
  onDelete: (id: string) => void;
}

function InputUploadFile({
  file,
  disabled,
  label,
  name,
  value,
  onDelete,
}: Props) {
  const [nameFile, setNameFile] = useState<string>(name ? name : "");

  const [loadImageError, setLoadImageError] = React.useState(false);
  // async function fromBlobToBase64(file: Blob): Promise<any> {
  //   return new Promise((resolve, _) => {
  //     const reader = new FileReader();
  //     reader.onloadend = () => resolve(reader.result);
  //     reader.readAsDataURL(file);
  //   });
  // }

  const imageError = () => {
    setLoadImageError(true);
  };
  const closeImageError = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setLoadImageError(false);
  };
  const priceSnack1 = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeImageError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  async function handleChange(e: ChangeEvent<HTMLInputElement>) {
    // let fileBase64: string = await fromBlobToBase64(e.target.files![0]);

    // let type: string = e.target.files![0].type;
    // type = "." + type.split("/")[1];
    //var img;
    if(e.target.dataset.info==="logo ricevuta"){
      var general
      if ((general = e.target.files![0])) {
          var img = new Image();
          var objectUrl = _URL.createObjectURL(general);
          img.onload = function () {
              if(img.width/img.height > 2 || img.height/img.width > 2||img.height<100||img.width<100){
                e.target.value = "";
                e.target.dataset.info = "";
                imageError();
              }
              else{
                const nameFile = e.target.files![0].name;
                setNameFile(nameFile);
                file(e.target.files![0]);
              }

              _URL.revokeObjectURL(objectUrl);
          };
          img.src = objectUrl;
          //file({ name: nameFile, type: type, base64: fileBase64 });
      }
    }
    else{
      const nameFile = e.target.files![0].name;
      setNameFile(nameFile);
      file(e.target.files![0]);
    }
  }

  return (
    <>
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={loadImageError}
      autoHideDuration={6000}
      onClose={closeImageError}
      message="size immagine sbagliata"
      action={priceSnack1}
      key={"caricamento_immagine"}
    >
    <Alert
      onClose={closeImageError}
      severity="warning"
      sx={{ width: "100%" }}
    >
      dimensione immagine sbagliata:
      altezza e larghezza minime 100 pixel;
      l'aspect ratio non deve superare 1:2(2:1) 

    </Alert>
  </Snackbar>
      {value !== undefined && value.id != "" ? (
        <div>
          <Typography style={{ fontSize: "13px", color: "#8a8a8a" }}>
            {label}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* <embed src={value.url} width={"50px"} /> */}
            {/* <img
              src={value.url}
              alt={value.name}
              style={{ maxHeight: "50px" }}
            ></img> */}
            <Link
              href={value.url}
              underline="hover"
              target="_blank"
              rel="noreferrer"
            >
              <Typography>{value.name}</Typography>
            </Link>
            <IconButton
              onClick={() => {
                onDelete(value.id);
                value.id=""; 
                setNameFile("");
              }}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              disabled={disabled}
              edge="end"
              aria-label="upload picture"
              component="label"
            >
            <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ) : (
        <TextField
          size="small"
          placeholder=" "
          label={label}
          variant="standard"
          disabled={true}
          value={nameFile}
          InputLabelProps={{ shrink: true, autoFocus: false}}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <IconButton
                style={{ paddingTop: 0, paddingBottom: 0 }}
                disabled={disabled}
                edge="end"
                aria-label="upload picture"
                component="label"
              >
                <input
                  disabled={disabled}
                  hidden
                  accept="image/jpeg, image/jpg, image/png, application/pdf"
                  type="file"
                  data-info= {label}
                  onChange={handleChange}
                />
                <PictureAsPdfIcon />
              </IconButton>
            ),
            
          }}
        />
      )}
    </>
  );
}

export default InputUploadFile;
