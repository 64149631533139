import React, { useContext, useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import BackgroundLine from "../../components/BackgroundLine/BackgroundLine";
import { Button, Card } from "@mui/material";
import { useParams } from "react-router-dom";
import { getSubscriptionById } from "../../models/subscription";

interface Props {
  price: number;
}

export const baseURL =
  process.env.REACT_APP_APP_BASE_URL || "http://localhost:3001";

export default function CheckoutForm({ price }: Props) {
  const { id } = useParams();

  //const { idSub } = useParams();
  const [state, setState] = useState<any>({
    end_date: "",
    name: "",
    price: "",
    start_date: "",
  });
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getSubscriptionById(id!).then((response) => {
      setState({
        //        end_date: response.data.end_date,
        name: response.data.association.name,
        //     start_date: response.data.start_date,
      });
    });
  }, []);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: baseURL + "?id=" + id,
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    setIsLoading(false);
  };

  return (
    <div className="pageContainer pageCenter">
      <h1>{state.name}</h1>
      <h3>{price / 100 + "€"}</h3>

      <Card style={{ padding: "3em" }}>
        <form id="payment-form">
          <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
          <div
            style={{
              marginTop: "1em",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={handleSubmit}
              variant="outlined"
              color="success"
              disabled={isLoading || !stripe || !elements}
              id="submit"
            >
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </Button>
          </div>
          {message && <div id="payment-message">{message}</div>}
        </form>
      </Card>
      <BackgroundLine />
    </div>
  );
}
