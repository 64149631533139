import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Alert,
  Snackbar,
} from "@mui/material";

import style from "./partnerEditStyle.module.scss";
import InputUploadFile from "../../components/InputUploadFile/InputUploadFile";
import TablePartnerPayment from "../../components/TablePartnerPayment/TablePartnerPayment";
import {
  createPartner,
  getPartnerById,
  updatePartnerById,
} from "../../models/partner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import InputCustom from "../../components/InputCustom/InputCustom";
import { authContext } from "../../context/auth";
import PATHS from "../../utils/paths";
import ModalPaymentSubscription from "../../components/ModalPaymentSubscription/ModalPaymentSubscription";
import { changeSubscription } from "../../models/subscription";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import { RoleNames } from "../../utils/roles";
import { FileType, deleteFile, getFile, sendFile } from "../../models/file";
import { fileTypes } from "../../utils/fileType";
import InputPassword from "../../components/InputPassword/InputPassword";
import CloseIcon from "@mui/icons-material/Close";
import TablePartnerServizi from "../../components/TablePartnerServizi/TablePartnerServizi";

function PartnerEdit() {
  const navigate = useNavigate();
  const { user } = useContext(authContext);
  const { id } = useParams();
  const location = useLocation();

  const [emailTutor, setEmailTutor] = useState<string>("");
  const [nameTutor, setNameTutor] = useState<string>("");
  const [cittadinanzaTutor, setCittadinanzaTutor] = useState<string>("");
  const [surnameTutor, setSurnameTutor] = useState<string>("");
  const [taxIdTutor, setTaxIdTutor] = useState<string>("");
  const [cityTutor, setCityTutor] = useState<string>("");
  const [provinceTutor, setProvinceTutor] = useState<string>("");
  const [addressTutor, setAddressTutor] = useState<string>("");
  const [capTutor, setCapTutor] = useState<string>("");
  const [phoneTutor, setPhoneTutor] = useState<string>("");
  //TODO: aggiungere
  const [relationship, setRelationship] = useState<string>("");

  const [status, setStatus] = useState<string>("");

  const [email, setEmail] = useState<string>("");
  const [cap, setCap] = useState<string>("");

  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");

  const [province, setProvince] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [taxId, setTaxId] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [ripetiPassword, setRipetiPassword] = useState<string>("");
  const [errorPassord, setErrorPassword] = useState<boolean>(false);

  const [cittadinanza, setCittadinanza] = useState<string>("");
  const [dateBirth, setDateBirth] = useState<string>("");

  const [modal, setModal] = useState<boolean>(false);
  const [modalConfirm, setModalConfirm] = useState<boolean>(false);

  const [idNewStatus, setIdNewStatus] = useState<string>("");

  const [scadenza_c_medico, setScadenza_c_medico] = useState<string | null>(
    null
  );
  const [scadenza_c_fiscale, setScadenza_c_fiscale] = useState<string | null>(
    null
  );
  const [scadenza_c_identita, setScadenza_c_identita] = useState<string | null>(
    null
  );

  const [disabled, setDisabled] = useState<boolean>(false);

  const [tutor, setTutor] = useState<boolean>(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [identita, setIdentita] = useState<FileType>();
  const [medico, setMedico] = useState<FileType>();
  const [fiscale, setFiscale] = useState<FileType>();
  const [openError, setOpenError] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");

  const emptyError = () => {
    setOpenError(true);
  };
  const closeEmptyError = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };
  const priceSnack1 = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeEmptyError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    if (location.state !== null) {
      setTaxId(location.state.taxId);
      setEmail(location.state.email);
      setDateBirth(location.state.date);
      tutorOn(location.state.date);
    }
    if (id) {
      getData();
      setDisabled(true);
    }
  }, []);

  useEffect(() => {
    tutorOn(dateBirth);
  }, [dateBirth]);

  function tutorOn(data: string) {
    const dataDiNascita = new Date(data);

    const dataCorrente = new Date();

    let age = dataCorrente.getFullYear() - dataDiNascita.getFullYear();

    // Verifica se il compleanno è già avvenuto quest'anno o no
    const meseCorrente = dataCorrente.getMonth() + 1;
    const giornoCorrente = dataCorrente.getDate();

    if (
      meseCorrente < dataDiNascita.getMonth() ||
      (meseCorrente === dataDiNascita.getMonth() &&
        giornoCorrente < dataDiNascita.getDate())
    ) {
      age--;
    }

    setTutor(age < 18);
  }

  function getData() {
    getPartnerById(id!).then((response) => {
      setEmail(response.data.email);
      setCap(response.data.cap);
      setProvince(response.data.province);
      setAddress(response.data.address);
      setCity(response.data.city);
      setPhone(response.data.phone_number);
      setTaxId(response.data.tax_id_code);
      setStatus(response.data.status);

      setCittadinanza(response.data.cittadinanza);
      setDateBirth(response.data.date_birth);

      setName(response.data.name);
      setSurname(response.data.surname);

      setScadenza_c_fiscale(response.data.scadenza_c_fiscale);
      setScadenza_c_identita(response.data.scadenza_c_identita);
      setScadenza_c_medico(response.data.scadenza_c_medico);

      if (!response.data.tutor) return;
      //TUTOR
      setEmailTutor(response.data.tutor.email);
      setCapTutor(response.data.tutor.cap);
      setProvinceTutor(response.data.tutor.province);
      setAddressTutor(response.data.tutor.address);
      setCityTutor(response.data.tutor.city);
      setPhoneTutor(response.data.tutor.phone_number);
      setTaxIdTutor(response.data.tutor.tax_id_code);
      setCittadinanzaTutor(response.data.tutor.cittadinanza);
      setNameTutor(response.data.tutor.name);
      setSurnameTutor(response.data.tutor.surname);
    });

    getFile(id!).then((response) => {
      response.data.forEach((element: FileType) => {
        if (element.docType === fileTypes.fiscale) setFiscale(element);
        else if (element.docType === fileTypes.indentita) setIdentita(element);
        else if (element.docType === fileTypes.medico) setMedico(element);
      });
    });
  }

  function createPartneFunction(sub: any) {
    if (taxId.length !== 16) {
      setError("codice fiscale non valido");
      emptyError();
    }
    let partner: any = {
      cittadinanza: cittadinanza,
      date_birth: dateBirth,
      address: address,
      cap: cap,
      city: city,
      email: email,
      scadenza_c_fiscale: scadenza_c_fiscale,
      scadenza_c_identita: scadenza_c_identita,
      scadenza_c_medico: scadenza_c_medico,
      phone_number: phone,
      province: province,
      tax_id_code: taxId,
      password: password,
      name: name,
      surname: surname,
      subscription: {
        associationId: user?.id!,
        start_validity: sub.startDate,
        end_validity: sub.endDate,
        status: "active",
      },
    };
    if (tutor)
      partner = {
        ...partner,
        tutor: {
          email: emailTutor,
          name: nameTutor,
          cittadinanza: cittadinanzaTutor,
          surname: surnameTutor,
          tax_id_code: taxIdTutor,
          city: cityTutor,
          province: provinceTutor,
          address: addressTutor,
          cap: capTutor,
          phone_number: phoneTutor,
          relationship: "",
        },
      };
    createPartner(partner)
      .then(() => navigate(PATHS.partnerList))
      .catch((error) => {
        setError("numero massimo di soci raggiunto");
        emptyError();
      });
  }

  function updatePartner() {
    if (taxId.length != 16) {
      setError("codice fiscale non valido");
      emptyError();
      return;
    }
    if (email == "") {
      setError("e-mail non inserita");
      emptyError();
      return;
    }
    let partner: any = {
      cittadinanza: cittadinanza,
      date_birth: dateBirth,
      address: address,
      cap: cap,
      city: city,
      email: email,
      scadenza_c_fiscale: scadenza_c_fiscale,
      scadenza_c_identita: scadenza_c_identita,
      scadenza_c_medico: scadenza_c_medico,
      phone_number: phone,
      province: province,
      tax_id_code: taxId,
      name: name,
      surname: surname,
    };
    if (tutor)
      partner = {
        ...partner,
        tutor: {
          email: emailTutor,
          name: nameTutor,
          cittadinanza: cittadinanzaTutor,
          surname: surnameTutor,
          tax_id_code: taxIdTutor,
          city: cityTutor,
          province: provinceTutor,
          address: addressTutor,
          cap: capTutor,
          phone_number: phoneTutor,
          relationship: "",
        },
      };
    updatePartnerById(id!, partner).then(() => setDisabled(true));
  }

  function createPartnerFunction(sub: any) {
    if (password !== ripetiPassword && status !== "new") {
      setErrorPassword(true);
      return;
    }
    status !== "new"
      ? createPartneFunction(sub)
      : changeSubscription({
          id: idNewStatus,
          end_validity: sub.endDate,
          start_validity: sub.startDate,
          status: "pending",
          sendEmail: true,
        }).then(() => {
          getData();
          setRefresh(true);
          setModal(false);
        });
  }

  return (
    <div className={"pageContainer " + style.content}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openError}
        autoHideDuration={6000}
        onClose={closeEmptyError}
        message="limite soci"
        action={priceSnack1}
        key={"max_partners"}
      >
        <Alert
          onClose={closeEmptyError}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
      {status === "new" && user?.role === RoleNames.ASSOCIATION && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() => setModalConfirm(true)}
            variant="contained"
            color="error"
            style={{
              alignSelf: "flex-end",
              marginTop: "50px",
            }}
          >
            Rifiuta Socio
          </Button>
          <Button
            onClick={() => setModal(true)}
            variant="contained"
            style={{
              backgroundColor: style.secondaryColor,
              alignSelf: "flex-end",
              marginTop: "50px",
            }}
          >
            Accetta Socio
          </Button>
        </div>
      )}
      {!id && user?.role === RoleNames.ASSOCIATION && (
        <Button
          onClick={() => setModal(true)}
          variant="contained"
          style={{
            backgroundColor: style.secondaryColor,
            alignSelf: "flex-end",
            marginTop: "50px",
          }}
        >
          Crea
        </Button>
      )}
      {id &&
        (user?.role === RoleNames.PARTNER ||
          user?.role === RoleNames.ASSOCIATION) &&
        status !== "new" && (
          <>
            {disabled ? (
              <Button
                onClick={() => setDisabled(false)}
                variant="contained"
                style={{
                  backgroundColor: style.secondaryColor,
                  alignSelf: "flex-end",
                  marginTop: "50px",
                }}
              >
                Modifica
              </Button>
            ) : (
              <Button
                onClick={updatePartner}
                variant="contained"
                color="success"
                style={{
                  alignSelf: "flex-end",
                  marginTop: "50px",
                }}
              >
                Salva
              </Button>
            )}
          </>
        )}
      <div style={{ display: "flex", gap: "30px", minWidth: "60%" }}>
        <div className={"cardInfo " + style.info}>
          <section>
            <Typography variant="h5">Informazioni Socio</Typography>
          </section>
          <div className={style.contentInfo}>
            <section>
              <InputCustom
                disabled={disabled}
                value={name}
                onChange={(value) => setName(value)}
                variant="standard"
                label="Nome"
              />
              <InputCustom
                disabled={disabled}
                value={surname}
                onChange={(value) => setSurname(value)}
                variant="standard"
                label="Cognome"
              />
            </section>
            <section>
              <InputCustom
                disabled={location.state !== null || disabled}
                value={email}
                onChange={(value) => setEmail(value)}
                variant="standard"
                label="Email Notifiche"
              />
              <InputCustom
                type="number"
                disabled={disabled}
                value={phone}
                onChange={(value) => setPhone(value)}
                variant="standard"
                label="Telefono"
              />
            </section>
            <section>
              <InputCustom
                disabled={location.state !== null || disabled}
                value={taxId}
                onChange={(value) => setTaxId(value)}
                variant="standard"
                label="Codice Fiscale"
              />
            </section>
            {!id && (
              <section>
                <InputPassword
                  error={errorPassord}
                  value={password}
                  onChange={(value) => {
                    errorPassord && setErrorPassword(false);
                    setPassword(value);
                  }}
                  variant="standard"
                  label="Password"
                />
                <InputPassword
                  error={errorPassord}
                  value={ripetiPassword}
                  onChange={(value) => {
                    errorPassord && setErrorPassword(false);
                    setRipetiPassword(value);
                  }}
                  variant="standard"
                  label="Ripeti Password"
                />
              </section>
            )}
          </div>
        </div>
        <div className="cardInfo" style={{ width: "50%" }}>
          <section>
            <Typography variant="h5">Informazioni Località</Typography>
          </section>
          <div style={{ minWidth: "250px" }}>
            <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
              <InputCustom
                disabled={disabled}
                value={cap}
                onChange={(value) => {
                  if (value.match("[^0-9]")) return;
                  setCap(value);
                }}
                variant="standard"
                label="CAP"
              />
              <InputCustom
                disabled={disabled}
                value={province}
                onChange={(value) => setProvince(value)}
                variant="standard"
                label="Provincia"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
              <InputCustom
                disabled={disabled}
                value={
                  disabled
                    ? new Date(dateBirth).toLocaleDateString("it-it", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })
                    : dateBirth
                }
                onChange={(value) => {
                  //console.log(value);
                  setDateBirth(value);
                }}
                variant="standard"
                label="Data di nascita"
                type="date"
              />
              <InputCustom
                disabled={disabled}
                value={city}
                onChange={(value) => setCity(value)}
                variant="standard"
                label="Città"
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "30px" }}>
              <InputCustom
                disabled={disabled}
                value={address}
                onChange={(value) => setAddress(value)}
                variant="standard"
                label="Indirizzo"
              />

              <InputCustom
                disabled={disabled}
                value={cittadinanza}
                onChange={(value) => setCittadinanza(value)}
                variant="standard"
                label="Cittadinanza"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={style.form}>
        <div className="cardInfo">
          <section>
            <Typography variant="h5">Documenti</Typography>
          </section>
          {id ? (
            <div className={style.document}>
              <div className={style.field}>
                <InputUploadFile
                  file={(file) =>
                    sendFile(file, "partner", id!, fileTypes.indentita)
                  }
                  value={identita}
                  onDelete={(id) => {
                    deleteFile(id).then(() => setIdentita(undefined));
                  }}
                  disabled={disabled}
                  label="C.I."
                />
                <TextField
                  disabled={disabled}
                  onChange={(e) => setScadenza_c_identita(e.target.value)}
                  value={scadenza_c_identita}
                  label="Data scadenza"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </div>
              <div className={style.field}>
                <InputUploadFile
                  file={(file) =>
                    sendFile(file, "partner", id!, fileTypes.fiscale)
                  }
                  value={fiscale}
                  onDelete={(id) => {
                    deleteFile(id).then(() => setFiscale(undefined));
                  }}
                  disabled={disabled}
                  label="C.F."
                />
                <TextField
                  disabled={disabled}
                  onChange={(e) => setScadenza_c_fiscale(e.target.value)}
                  value={scadenza_c_fiscale}
                  label="Data scadenza"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </div>
              <div className={style.field}>
                <InputUploadFile
                  file={(file) =>
                    sendFile(file, "partner", id!, fileTypes.medico)
                  }
                  value={medico}
                  onDelete={(id) => {
                    deleteFile(id).then(() => setMedico(undefined));
                  }}
                  disabled={disabled}
                  label="Certificato Medico"
                />
                <TextField
                  disabled={disabled}
                  onChange={(e) => setScadenza_c_medico(e.target.value)}
                  value={scadenza_c_medico}
                  label="Data scadenza"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                />
              </div>
            </div>
          ) : (
            <div className={style.document}>
              <Typography variant="caption" style={{ color: "#8a8a8a" }}>
                Documenti da inserire dopo la creazione del socio
              </Typography>
            </div>
          )}
        </div>
        {tutor && (
          <div className={"cardInfo " + style.info}>
            <section>
              <Typography variant="h5">Informazioni Tutore</Typography>
            </section>
            <div className={style.contentInfo}>
              <section>
                <InputCustom
                  disabled={disabled}
                  value={nameTutor}
                  onChange={(value) => setNameTutor(value)}
                  variant="standard"
                  label="Nome"
                />
                <InputCustom
                  disabled={disabled}
                  value={surnameTutor}
                  onChange={(value) => setSurnameTutor(value)}
                  variant="standard"
                  label="Cognome"
                />
              </section>
              <section>
                <InputCustom
                  disabled={disabled}
                  value={emailTutor}
                  onChange={(value) => setEmailTutor(value)}
                  variant="standard"
                  label="Email"
                />
                <InputCustom
                  disabled={disabled}
                  value={phoneTutor}
                  onChange={(value) => setPhoneTutor(value)}
                  variant="standard"
                  label="Telefono"
                />
              </section>
              <section>
                <InputCustom
                  disabled={disabled}
                  value={taxIdTutor}
                  onChange={(value) => setTaxIdTutor(value)}
                  variant="standard"
                  label="Codice Fiscale"
                />
              </section>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "30px" }}
              >
                <InputCustom
                  disabled={disabled}
                  value={capTutor}
                  onChange={(value) => setCapTutor(value)}
                  variant="standard"
                  label="CAP"
                />
                <InputCustom
                  disabled={disabled}
                  value={provinceTutor}
                  onChange={(value) => setProvinceTutor(value)}
                  variant="standard"
                  label="Provincia"
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "30px" }}
              >
                <InputCustom
                  disabled={disabled}
                  value={cityTutor}
                  onChange={(value) => setCityTutor(value)}
                  variant="standard"
                  label="Città"
                />
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "30px" }}
              >
                <InputCustom
                  disabled={disabled}
                  value={addressTutor}
                  onChange={(value) => setAddressTutor(value)}
                  variant="standard"
                  label="Indirizzo"
                />

                <InputCustom
                  disabled={disabled}
                  value={cittadinanzaTutor}
                  onChange={(value) => setCittadinanzaTutor(value)}
                  variant="standard"
                  label="Cittadinanza"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {id && (
        <TablePartnerPayment
          newStatus={(id) => setIdNewStatus(id)}
          refresh={refresh}
        />
      )}
      {id && <TablePartnerServizi statusNew={idNewStatus !== ""} />}

      <ModalPaymentSubscription
        onConfirm={(sub) => {
          createPartnerFunction(sub);
        }}
        onClose={() => setModal(false)}
        open={modal}
      />

      <ModalConfirm
        title="Sicuro di voler riufiutare questo socio?"
        open={modalConfirm}
        onClose={() => setModalConfirm(false)}
        onConfirm={() =>
          changeSubscription({
            id: idNewStatus,
            end_validity:
              new Date().toISOString().split("T")[0] +
              " " +
              new Date().toISOString().split("T")[1].split(".")[0],
            start_validity:
              new Date().toISOString().split("T")[0] +
              " " +
              new Date().toISOString().split("T")[1].split(".")[0],
            status: "not active",
          }).then(() => {
            setModalConfirm(false);
            getData();
          })
        }
      />
    </div>
  );
}

export default PartnerEdit;
