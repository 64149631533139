const PATHS = {
  paymentPage: "/paymentPage",
  settingsAdmin: "/settings",

  associationList: "/associationList",
  associationLogin: "/associationLogin",
  associationSignin: "/associationSignin",
  partnerList: "/partnerList",
  partnerEdit: "/partnerList/partnerEdit",
  partnerAdd: "/partnerList/partnerNew",

  associationProfile: "associationProfile",

  socioLogin: "/socioLogin",
  partnerSignin: "/partnerSignin",
  partnerProfile: "/partnerProfile",

  resetPassword: "/resetPassword",

  adminLogin: "/adminLogin",
  adminAssociationList: "/adminAssociationList",
  adminPaymentsList: "/adminAssociationList/adminPaymentsList",
  adminAssociationProfile: "/adminAssociationList/associationProfile",

  ticketList: "/adminAssociationList/ticketList",
  ticketChat: "chat",
  ticketListAssociation: "/ticketList",

  sendSubPartner: "/sendSubPartner",
  privacyPartner: "/privacyPartner",
};
export default PATHS;
