import React from "react";
import { Breadcrumbs, Chip, emphasize, styled } from "@mui/material";
import useBreadcrumbs, { BreadcrumbsRoute } from "use-react-router-breadcrumbs";

import style from "./breadcrumbsCustomStyle.module.scss";
import PATHS from "../../utils/paths";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

export const routes: BreadcrumbsRoute[] = [
  {
    path: "/",
    breadcrumb: "Home",
    props: { icon: <HomeIcon fontSize="small" /> },
  },

  { path: PATHS.paymentPage, breadcrumb: "Payment Page" },

  { path: PATHS.associationList, breadcrumb: "Lista Associazioni" },

  { path: PATHS.adminAssociationList, breadcrumb: "Lista Associazioni" },
  { path: PATHS.adminPaymentsList, breadcrumb: null },
  { path: PATHS.adminPaymentsList + "/:id", breadcrumb: "Lista Pagamenti" },

  { path: PATHS.adminAssociationProfile, breadcrumb: null },
  {
    path: PATHS.adminAssociationProfile + "/:id",
    breadcrumb: "Profilo",
  },
  { path: PATHS.ticketList, breadcrumb: null },
  { path: PATHS.ticketList + "/:id", breadcrumb: "Lista Ticket" },
  { path: PATHS.ticketList + "/:id/" + PATHS.ticketChat, breadcrumb: null },
  {
    path: PATHS.ticketList + "/:id/" + PATHS.ticketChat + "/:id",
    breadcrumb: "Ticket",
  },
  { path: PATHS.ticketListAssociation + "/:id", breadcrumb: "Lista Ticket" },
  {
    path: PATHS.ticketListAssociation + "/:id/" + PATHS.ticketChat + "/:id",
    breadcrumb: "Ticket",
  },

  { path: PATHS.socioLogin, breadcrumb: "Login" },
  { path: PATHS.associationLogin, breadcrumb: "Login" },
  { path: PATHS.adminLogin, breadcrumb: "Login" },

  { path: PATHS.associationSignin, breadcrumb: "Signin" },

  { path: PATHS.partnerList, breadcrumb: "Lista Partner" },
  { path: PATHS.partnerEdit, breadcrumb: "Profilo Partner" },
  {
    path: PATHS.partnerList + "/" + PATHS.partnerAdd,
    breadcrumb: "Nuovo Socio",
  },

  {
    path: PATHS.associationProfile + "/:id",
    breadcrumb: "Profilo",
  },

  {
    path: PATHS.associationList + "/" + PATHS.associationProfile + "/:id",
    breadcrumb: "Profilo",
  },
];

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
      cursor: "pointer",
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}) as typeof Chip;

function BreadcrumbsCustom() {
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

  return (
    <div className={style.breadCrumbsContainer}>
      <Breadcrumbs aria-label="breadcrumb" className={style.breadcrumbs}>
        {breadcrumbs.map(({ match, breadcrumb, icon }: any) => {
          return (
            <StyledBreadcrumb
              icon={icon}
              component="a"
              key={match.pathname}
              onClick={() => navigate(match.pathname)}
              label={breadcrumb}
            />
          );
        })}
      </Breadcrumbs>
    </div>
  );
}

export default BreadcrumbsCustom;
