export function stringDate(date?: string, format = false) {
  if (!format) {
    return date ? new Date(date).toLocaleString("it-IT") : "";
  }
  return date
    ? new Date(date).toLocaleString("it-IT", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
    : "";
}
