import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
//style
import style from "./modalPaymentServizioStyle.module.scss";
import { authContext } from "../../context/auth";
import { RoleNames } from "../../utils/roles";
import { getServiceById } from "../../models/serviceAssociation";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: (service: Service) => void;
}

type Service = {
  serviceId: string;
  start_validity: string;
  end_validity: string;
  price: number;
};

const today = new Date().toISOString().split("T")[0];

function ModalPaymentServizio({ open, onClose, onConfirm }: Props) {
  const { user } = useContext(authContext);
  const [services, setServices] = useState<{ name: string; id: string }[]>([]);

  const [price, setPrice] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("");
  const [serviceId, setServiceId] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  useEffect(() => {
    user?.role === RoleNames.ASSOCIATION &&
      getServiceById(user.id).then((response) => {
        setServices(response.data);
      });
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <div
        className={style.modal}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Typography variant="h4">Nuova Iscrizione</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div>
            <FormControl style={{ width: "50%" }}>
              <InputLabel>Servizio</InputLabel>
              <Select
                label="Servizio"
                onChange={(e: SelectChangeEvent) =>
                  setServiceId(e.target.value)
                }
              >
                {services.map((service) => {
                  return (
                    <MenuItem key={service.id} value={service.id}>
                      {service.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              style={{ width: "50%" }}
              type="number"
              label="Prezzo"
              onChange={(e) => setPrice(parseFloat(e.target.value))}
            ></TextField>
          </div>
          <div>
            <TextField
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
              label="Data di inizio"
              type="date"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: today, // Imposta la data minima come oggi
              }}
            />
            <TextField
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
              label="Data di fine"
              type="date"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: startDate,
              }}
            />
          </div>
        </div>
        <Button
          variant="outlined"
          color="success"
          onClick={() =>
            onConfirm({
              end_validity: endDate,
              price: price,
              serviceId: serviceId,
              start_validity: startDate,
            })
          }
        >
          Aggiungi pagamento Servizio
        </Button>
      </div>
    </Modal>
  );
}

export default ModalPaymentServizio;
