import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PATHS from "../../utils/paths";
//function component
import CardAuth from "../../components/CardAuth/CardAuth";
//mui
import { Button, TextField, Typography } from "@mui/material";
//api
import { authContext } from "../../context/auth";
import { me } from "../../models/user";
import { login } from "../../models/auth";
import BackgroundLine from "../../components/BackgroundLine/BackgroundLine";
import InputPassword from "../../components/InputPassword/InputPassword";

function AssociationLogin() {
  const navigate = useNavigate();
  const { setToken, setRefresh, setUser, user } = useContext(authContext);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    if (user) navigate(PATHS.partnerList);
  }, [user]);

  return (
    <div className={"pageContainer pageCenter"}>
      <div style={{ marginBottom: "30px" }}>
        <CardAuth
          title={"Login Associazione"}
          buttons={
            <Button
              variant="contained"
              onClick={() => {
                login({ user: email, pass: password })
                  .then((res: any) => {
                    if (res.status === 201) {
                      const json = res.data;
                      setToken(json.authorization);
                      setRefresh(json.refresh);
                    }
                  })
                  .then(me)
                  .then((res) => {
                    setUser(res.data);
                    navigate(PATHS.partnerList);
                  });
              }}
            >
              Login
            </Button>
          }
        >
          <>
            <TextField
              size="small"
              value={email}
              label="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputPassword
              onChange={(value) => setPassword(value)}
              value={password}
            />

            <section>
              <Typography
                variant="overline"
                component={Link}
                to={PATHS.resetPassword}
              >
                Hai dimenticato la password?
              </Typography>
              <Typography
                variant="overline"
                component={Link}
                to={PATHS.associationSignin}
              >
                Non sei ancora Inscritto?
              </Typography>
            </section>
          </>
        </CardAuth>
      </div>
      {/* <Typography variant="overline" component={Link} to={PATHS.adminLogin}>
        admin
      </Typography> */}
      <BackgroundLine />
    </div>
  );
}

export default AssociationLogin;
