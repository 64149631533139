import api from "./api";
import { Pagination } from "./association";

type Product = {
  id: string;
  title: string;
  price: number;
  quantity: number;
};

type PaymentRequestBody = {
  products: Product[];
  currency: string;
};

type Keys = {
  associationId: string;
  public_key: string;
  private_key: string;
};

export const getAllPaymemtByAssociation = (
  idAssociation: string,
  pagination: Pagination,
  search: string
) => {
  return api.get("/payment", {
    params: {
      id: idAssociation,
      take: pagination.take,
      page: pagination.page,
      q: search,
    },
  });
};

export const createPaymentIntent = (id: string) => {
  return api.post("/online-payment/create-payment-intent", {
    associationId: id,
  });
};
export const createCheckoutSession = () => {
  return api.post("/online-payment/create-checkout-session");
};

export const sendKeys = (keys: Keys) => {
  return api.post("/online-payment/keys", keys);
};
