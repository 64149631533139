import api from "./api";

const login = (body: any) => {
  return api.post("/auth/login/association", body).then((res: any) => {
    api.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
    localStorage.setItem("token", res.data.authorization);
    localStorage.setItem("refresh", res.data.refresh);
    return res;
  });
};

const loginAdmin = (body: any) => {
  return api.post("/auth/login/admin", body).then((res: any) => {
    api.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
    localStorage.setItem("token", res.data.authorization);
    localStorage.setItem("refresh", res.data.refresh);
    return res;
  });
};

const loginSocio = (body: any) => {
  return api.post("/auth/login/partner", body).then((res: any) => {
    api.defaults.headers.common["Authorization"] = `Bearer ${res.data.token}`;
    localStorage.setItem("token", res.data.authorization);
    localStorage.setItem("refresh", res.data.refresh);
    return res;
  });
};

const refresh = (refresh: string) => {
  return api.post("/auth/refresh", { token: refresh });
};

export { login, loginAdmin, loginSocio, refresh };
