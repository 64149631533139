import React from "react";
import { Alert, Snackbar } from "@mui/material";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

function SnackBarCustom({ open, setOpen }: Props) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Alert variant="filled" severity="success">
        Iscrizione inviata con successo, se l'associazione accetterà ti verra
        inviata un email per il pagamento
      </Alert>
    </Snackbar>
  );
}

export default SnackBarCustom;
