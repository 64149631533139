import React from "react";
import style from "./backgroundLine.module.scss";
/*
-prospective: lunghezza prospettiva da 0 in su...se 0 parte dall'origine
-perspectiveOrigin: dove si trova il punto di origine da dove parte la prospettiva

//si puo ridurre cosi ma funziona solo con elemento, perchè ognuno avra la sua prospettiva
.panel--separate {
  transform: perspective(400px) rotateY(45deg);
  transform-origin: 50% 50%
}

<div
        style={{
          perspective: "400px",
          perspectiveOrigin: "50% 50%",
          border: "1px solid #CCC",
        }}
      >
        <div
          style={{
            width: "200px",
            height: "200px",
            position: "relative",
            transformStyle: "preserve-3d",
            transform: "translateZ(-100px)",
          }}
        >
          <div
            style={{
              background: "red",
              transform: "rotateX(-90deg) translateZ(100px)",
              lineHeight: "200px",
              position: "absolute",
              width: "200px",
              height: "200px",
              border: "2px solid black",
            }}
          ></div>
        </div>
      </div>
*/

function BackgroundLine() {
  return (
    <div className={style.page}>
      <div className={style.frontFace}></div>
      <div className={style.verticalLine}></div>
      <div className={style.prospectiveLine}></div>
    </div>
  );
}

export default BackgroundLine;
