import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  InputAdornment,
  Typography,
  Divider,
} from "@mui/material";

//style
import style from "./modalPaymentPackageStyle.module.scss";
import { Package, addNewPackage, getAllPackage } from "../../models/package";
import { TextFields } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useParams } from "react-router-dom";

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function ModalPaymentPackage({ open, onClose, onConfirm }: Props) {
  const { id } = useParams();
  const [packageData, setPackageData] = useState<Package[]>([]);
  const [packageSelected, setPackageSelected] = useState<string>("");
  const [timeSelected, setTimeSelected] = useState<string>("");

  const [price, setPrice] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);

  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  useEffect(() => {
    getListPackage();
  }, []);

  function getListPackage() {
    getAllPackage().then((response) => {
      const data: Package[] = [];
      response.data.forEach((element: Package) => {
        data.push({
          id: element.id,
          max_partner: element.max_partner,
          min_partner: element.min_partner,
          annual_cost: element.annual_cost,
          montly_cost: element.montly_cost,
          name: element.name,
        });
      });
      setPackageData(data);
    });
  }

  useEffect(() => {
    if (packageSelected !== "" && timeSelected !== "") {
      const pack = packageData.find((e) => e.id === packageSelected);
      setPrice(
        timeSelected === "monthly" ? pack!.montly_cost : pack!.annual_cost
      );
    }
  }, [packageSelected, timeSelected]);

  useEffect(() => {
    let disc = discount ? discount : 0;
    var totd =Number.parseFloat((price * (disc / 100)).toFixed(2));
    setTotalDiscount(totd);
    setTotalPrice(Number.parseFloat((price - totd).toFixed(2)));
  }, [price, discount]);

  return (
    <Modal open={open} onClose={onClose}>
      <div className={style.modal}>
        <div className={style.inputLables}>
          <div className={style.input}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel>Pacchetto</InputLabel>
              <Select
                label="Pacchetto"
                onChange={(e: SelectChangeEvent) =>
                  setPackageSelected(e.target.value)
                }
              >
                {packageData.map((onePackage) => {
                  return (
                    <MenuItem key={onePackage.id} value={onePackage.id}>
                      {onePackage.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          <div className={style.input}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel>Pagamento</InputLabel>
              <Select
                label="Pagamento"
                onChange={(e: SelectChangeEvent) =>
                  setTimeSelected(e.target.value)
                }
              >
                <MenuItem value={"monthly"}>MENSILE</MenuItem>
                <MenuItem value={"annual"}>ANNUALE</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <TextField
          value={discount}
          onChange={(event) => {
            setDiscount(
              Math.min(Math.max(parseInt(event.target.value), 0), 100)
            );
          }}
          className={style.Sconto}
          label="Sconto"
          type="number"
          inputProps={{ min: 0, max: 100 }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
        <div className={style.textFields}>
          <section className={style.price}>
            <div>
              <Typography>Prezzo:</Typography>

              <Typography>{price}€</Typography>
            </div>
            <div>
              <Typography>Sconto:</Typography>

              <Typography>-{totalDiscount}€</Typography>
            </div>
            <Divider />
            <div>
              <Typography>Totale:</Typography>

              <Typography>{totalPrice}€</Typography>
            </div>
          </section>
        </div>
        <div className={style.button}>
          <Button
            variant="contained"
            onClick={() =>
              addNewPackage({
                packageId: packageSelected,
                associationId: id!,
                type: timeSelected,
                discount: totalDiscount.toString(),
              }).then(() => onConfirm())
            }
          >
            REGISTRA PAGAMENTI
          </Button>
        </div>
      </div>
    </Modal>
  );
}
export default ModalPaymentPackage;
