import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import style from "./adminaymentsListStyle.module.scss";

//api
import { getAllPaymemtByAssociation } from "../../models/payment";
//component function
import TableCustom, { Columns } from "../../components/TableCustom/TableCustom";
import ModalPaymentPackage from "../../components/ModalPaymentPackage/ModalPaymentPackage";
//mui
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import { stringDate } from "../../utils/stringDate";

function AdminPaymentsList() {
  const { id } = useParams();
  const [columns, setColumns] = useState<Columns[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<number>(0);

  const [take, setTake] = useState<number>(0);

  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setColumns([
      {
        label: "Data Pagamento",
        name: "start_validity",
      },
      {
        label: "Fine validità",
        name: "end_validity",
      },
      {
        label: "Pacchetto",
        name: "name",
      },
      {
        label: "Stato",
        name: "status",
        status: {
          value: [
            { label: "Pending", name: "pending" },
            { label: "Non Pagato", name: "not active" },
            { label: "Pagato", name: "active" },
          ],
          green: "active",
          gray: "pending",
          red: "not active",
        },
      },
      {
        label: "",
        name: "action",
        action: (row) => (
          <IconButton>
            <DownloadIcon />
          </IconButton>
        ),
      },
    ]);
  }, []);

  useEffect(() => {
    getData(1, 10);
  }, []);

  function getData(page: number, take: number, search = "") {
    getAllPaymemtByAssociation(id!, { take: 10, page: page }, search).then(
      (response) => {
        const data: any[] = [];
        response.data.data.forEach((element: any) => {
          data.push({
            start_validity: stringDate(element.start_validity),
            end_validity: stringDate(element.start_validity),
            name: element.package.name,
            status: element.status,
            discount: element.disocount
          });
        });
        setRows(data);
        setTotalRow(response.data.total);
      }
    );
  }

  return (
    <div className={"pageContainer " + style.container}>
      <section className={style.filter}>
        <FormControl className={style.select}>
          <InputLabel>Stato</InputLabel>
          <Select
            label={"Stato"}
            onChange={(e: SelectChangeEvent) => getData(1, 10, e.target.value)}
          >
            <MenuItem value={""}>Tutti</MenuItem>
            <MenuItem value={"open"}>Aperto</MenuItem>
            <MenuItem value={"close"}>Chiuso</MenuItem>
          </Select>
        </FormControl>
        <div>
          <Button variant="contained" onClick={() => setOpenModal(true)}>
            Aggiungi Pagamento
          </Button>
        </div>
      </section>
      <TableCustom
        columns={columns}
        rows={rows}
        pagination={{
          defaultTake: 10,
          maxRows: totalRow,
          onChange(take, page) {
            setTake(take);
            getData(page, take);
          },
        }}
      />
      <ModalPaymentPackage
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        onConfirm={() => {
          setOpenModal(false);
          getData(1, take);
        }}
      />
    </div>
  );
}

export default AdminPaymentsList;
