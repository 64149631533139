export const fileTypes = {
  empty: "",
  indentita: "carta d'identità",
  fiscale: "codice fiscale",
  medico: "certificato medico",
  statuto: "statuto",
  logo: "logo",
  aoa: "atto costitutivo", //articles of association 
  tutor: "tutore",
  receipt_logo: "logo ricevuta",
  other: "altro",
};
