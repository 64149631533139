import React, { useEffect } from "react";
import { createCheckoutSession } from "../../models/payment";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";

function PredefinedCheckoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    createCheckoutSession().then((res) =>
      window.location.replace(res.data.url)
    );
  }, []);

  return (
    <div>
      <Container maxWidth="sm">
        <Paper sx={{ padding: "2rem", marginTop: "2rem" }}>
          <Typography variant="h4" gutterBottom>
            Pagamento
          </Typography>
          <form onSubmit={() => {}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Nome sulla carta"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Numero di carta"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  label="Scadenza (MM/AA)"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField required label="CVV" fullWidth variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="select-label">Tipo di carta</InputLabel>
                  <Select
                    labelId="select-label"
                    label="Tipo di carta"
                    variant="outlined"
                  >
                    <MenuItem value="visa">Visa</MenuItem>
                    <MenuItem value="mastercard">Mastercard</MenuItem>
                    <MenuItem value="amex">American Express</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Paga
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
}

export default PredefinedCheckoutPage;
