import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import StripeKeyForm from "../../components/StripeKeyForm/StripeKeyForm";

//mui
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";

import style from "./associationEditStyle.module.scss";

//component function
import InputUploadFile from "../../components/InputUploadFile/InputUploadFile";
import TableService from "../../components/TableService/TableService";
import InputCustom from "../../components/InputCustom/InputCustom";
import InputPassword from "../../components/InputPassword/InputPassword";
import SnackNotActive from "../../components/SnackNotActive/SnackNotActive";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import TableAssociationAdmins from "../../components/TableAssociationAdmins/TableAssociationAdmins";
//api
import {
  Association,
  disableAssociation,
  getAssociationInfo,
  updateAssociationInfo,
} from "../../models/association";
import { Type, getType } from "../../models/type";
import { createSubscription } from "../../models/subscription";
import { FileType, deleteFile, getFile, sendFile } from "../../models/file";
import { createAssociationInfo } from "../../models/association";
//utils
import { authContext } from "../../context/auth";
import { RoleNames } from "../../utils/roles";
import PATHS from "../../utils/paths";
import { fileTypes } from "../../utils/fileType";
//icon
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ArticleIcon from "@mui/icons-material/Article";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SnackBarCustom from "../../components/SnackBar/SnackBar";
import CloseIcon from "@mui/icons-material/Close";

function AssociationEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useContext(authContext);

  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [placeholderKeys, setPlaceholderKeys] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [password, setPassword] = useState<string>("");

  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [CAP, setCAP] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [vat_number, setVat_number] = useState<string>("");
  const [vat_numberError, setVat_numberError] = useState<boolean>(false);
  const [types, setTypes] = useState<Type[]>([]);
  const [type, setType] = useState<Type>({ id: "", name: "" });

  const [price, setPrice] = useState<number | undefined>(0);
  const [dateSubStart, setDateSubStart] = useState<string | undefined>("");
  const [dateSubEnd, setDateSubEnd] = useState<string | undefined>("");

  const [Rappresentante, setRappresentante] = useState<FileType>();
  const [statuto, setStatuto] = useState<FileType>();
  const [logo, setLogo] = useState<FileType>();
  const [atto_costitutivo, setAtto_costitutivo] = useState<FileType>();
  const [logo_ricevuta, setLogo_ricevuta] = useState<FileType>();
  const [altro, setAltro] = useState<FileType>();

  const [enabled, setEnabled] = useState<boolean>(true);

  const [presidente, setPresidente] = useState<string>("");

  const [iban, setIban] = useState<string>("");

  const [statutoEmpty, setStatutoEmpty] = useState<boolean>(true);
  const [Atto_costitutivoEmpty, setAtto_costitutivoEmpty] =
    useState<boolean>(true);
  const [openErrorN, setOpenError] = React.useState(false);
  const [error, setError] = React.useState("");

  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    if (id !== undefined) getData();
    user &&
      user.role !== RoleNames.PARTNER &&
      getType().then((response) => setTypes(response.data));
  }, []);

  function checkError(create = false) {
    setShowError(false);
    if (
      !address ||
      !CAP ||
      !city ||
      !presidente ||
      !email ||
      !name ||
      !phone ||
      !province ||
      !vat_number
    ) {
      setShowError(true);
      return true;
    }
    if (create && !password) {
      setShowError(true);
      return true;
    }
    return false;
  }

  function createAssociation() {
    if (checkError()) return;
    createAssociationInfo({
      address: address,
      cap: CAP,
      city: city,
      presidente: presidente,
      email: email,
      iban: iban,
      password: password,
      name: name,
      phone_number: phone,
      province: province,
      vat_number: vat_number,
    }).then(() => {
      navigate(-1);
    });
  }

  function updateAssociation() {
    if (checkError()) return;

    updateAssociationInfo(id!, {
      address: address,
      presidente: presidente,
      iban: iban,
      cap: CAP,
      city: city,
      email: email,
      name: name,
      phone_number: phone,
      province: province,
      vat_number: vat_number,
      typeId: type?.id,
    }).then(() => {
      setDisabled(true);
      getData();
    });
  }

  function getData() {
    getAssociationInfo(id!).then((response) => {
      //controllo se utente esterno a associazione non attiva
      if (
        !response.data.active &&
        (!user || (user.id !== id && user.role !== RoleNames.ADMIN))
      ) {
        navigate(-1);
      }
      getFile(id!).then((response) => {
        response.data.forEach((element: FileType) => {
          if (element.docType === fileTypes.indentita)
            setRappresentante(element);
          else if (element.docType === fileTypes.statuto) {
            setStatuto(element);
            setStatutoEmpty(false);
          } else if (element.docType === fileTypes.logo) setLogo(element);
          else if (element.docType === fileTypes.aoa) {
            setAtto_costitutivo(element);
            setAtto_costitutivoEmpty(false);
          } else if (element.docType === fileTypes.receipt_logo)
            setLogo_ricevuta(element);
          else if (element.docType === fileTypes.other) setAltro(element);

          //console.log(element);
        });
      });

      const data: Association = response.data;
      setName(data.name);
      setEmail(data.email);
      setIban(data.iban);
      setAddress(data.address);
      setCity(data.city);
      setCAP(data.cap);
      setProvince(data.province);
      setPhone(data.phone_number);
      setVat_number(data.vat_number);
      setType(data.type!);
      //setDisabled(true);
      setPrice(data.price?.price);
      setDateSubStart(data.price?.start_date);
      setDateSubEnd(data.price?.end_date);
      setEnabled(data.active!);
      setPresidente(data.presidente!);
      setPlaceholderKeys(data.keysSaved!);
    });
  }

  const openError = () => {
    setOpenError(true);
  };
  const closeError = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };
  const priceSnack = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={"pageContainer " + style.container}>
      <SnackNotActive status={enabled} />
      <SnackBarCustom open={snackbar} setOpen={(value) => setSnackbar(value)} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openErrorN}
        autoHideDuration={6000}
        onClose={closeError}
        message="inserire documento"
        action={priceSnack}
        key={"insert_documents"}
      >
        <Alert onClose={closeError} severity="warning" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>

      <div className={style.title}>
        {user?.role === RoleNames.ADMIN && id && (
          <Button
            color={enabled ? "error" : "success"}
            variant="contained"
            onClick={() => setOpenModalCancel(true)}
          >
            {(enabled ? "Disabilita" : "Abilita") + " Associazione"}
          </Button>
        )}
        {user?.role === RoleNames.ADMIN || user?.id === id ? (
          <div style={{ marginLeft: "auto" }}>
            {disabled ? (
              <Button
                style={{
                  backgroundColor: style.secondaryColor,
                }}
                className={style.button}
                variant="contained"
                onClick={() => setDisabled(false)}
              >
                Modifica
              </Button>
            ) : id ? (
              <Button
                className={style.button}
                variant="contained"
                color="success"
                onClick={() => {
                  if (Atto_costitutivoEmpty) {
                    setError("atto costitutivo mancante");
                    openError();
                  } else if (statutoEmpty) {
                    setError("statuto mancante");
                    openError();
                  } else if (vat_numberError) {
                    setError("partita iva non valida");
                    openError();
                  } else if (!presidente) {
                    setError("inserire nominativo presidente");
                    openError();
                  } else updateAssociation();
                }}
              >
                Salva
              </Button>
            ) : (
              <Button
                className={style.button}
                variant="contained"
                onClick={() => createAssociation()}
              >
                Crea
              </Button>
            )}
          </div>
        ) : (
          <Button
            onClick={() => {
              user && user.role === RoleNames.PARTNER
                ? createSubscription({
                    associationId: id!,
                    partnerId: user?.id!,
                    status: "new",
                  }).then(() => {
                    setSnackbar(true);
                  })
                : navigate(PATHS.partnerSignin, {
                    state: { idAssociation: id },
                  });
            }}
            style={{ marginLeft: "auto" }}
            className={style.button}
            variant="contained"
          >
            Iscriviti
          </Button>
        )}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "1em" }}
      >
        <div className={style.content}>
          <div className={style.form}>
            <section className={style.infoAssocia}>
              <div className={style.imgType}>
                {!disabled &&
                  (logo ? (
                    <IconButton
                      onClick={() => {
                        deleteFile(logo.id).then(() => setLogo(undefined));
                      }}
                      style={{ position: "absolute", alignSelf: "flex-end" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      style={{ position: "absolute", alignSelf: "flex-end" }}
                      component="label"
                    >
                      <input
                        disabled={disabled}
                        hidden
                        accept="image/*,application/pdf"
                        type="file"
                        onChange={(e) => {
                          sendFile(
                            e.target.files![0],
                            "association",
                            id!,
                            fileTypes.logo
                          ).then((response) => setLogo(response.data));
                        }}
                      />
                      <EditIcon />
                    </IconButton>
                  ))}

                <div className={style.imgContainer}>
                  <img className={style.img} src={logo?.url} alt="logo" />
                </div>

                {disabled ? (
                  <Chip
                    label={type?.name ? type.name : "Associazione"}
                    className={style.chipType}
                    style={{}}
                  />
                ) : (
                  <FormControl style={{ minWidth: "100px" }}>
                    <InputLabel>Tipologia</InputLabel>
                    <Select
                      value={type?.id}
                      variant="standard"
                      disabled={disabled}
                      label="Tipologia"
                      onChange={(e: SelectChangeEvent) =>
                        setType({ ...type, id: e.target.value })
                      }
                    >
                      {types?.map((element) => {
                        return (
                          <MenuItem key={element.id} value={element.id}>
                            {element.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </div>

              <div style={{ width: "100%" }}>
                <InputCustom
                  error={showError && !name}
                  variant="standard"
                  label="Nome"
                  value={name}
                  disabled={disabled}
                  onChange={(value) => setName(value)}
                  styleInput={{ fontSize: 40, overflowWrap: "break-word" }}
                />
                <div className={style.principalInfo}>
                  <div className={`cardInfo`}>
                    <section>
                      <Typography variant={"h5"}>Contatti</Typography>
                    </section>
                    <div className={`${style.otherInfo}`}>
                      <InputCustom
                        error={showError && !email}
                        onClick={() => window.open(`mailto:${email}`, "_blank")}
                        type="email"
                        variant="standard"
                        label="Email"
                        value={email}
                        disabled={disabled}
                        onChange={(value) => setEmail(value)}
                        icon={<MailOutlineIcon fontSize="small" />}
                      />
                      <InputCustom
                        error={showError && !phone}
                        onClick={() => window.open(`tel:${phone}`, "_blank")}
                        type="tel"
                        variant="standard"
                        label="Telefono"
                        value={phone}
                        disabled={disabled}
                        onChange={(value) => setPhone(value)}
                        icon={<LocalPhoneIcon fontSize="small" />}
                      />
                      <InputCustom
                        error={showError && !presidente}
                        variant="standard"
                        label="Nominativo Presidente"
                        value={presidente}
                        disabled={disabled}
                        onChange={(value) => setPresidente(value)}
                      />

                      {!id && user?.role === RoleNames.ADMIN && (
                        <InputPassword
                          error={showError && !password}
                          value={password}
                          variant="standard"
                          onChange={(value) => setPassword(value)}
                        />
                      )}
                      {(user?.role === RoleNames.ADMIN ||
                        user?.role === RoleNames.ASSOCIATION) && (
                        <InputCustom
                          error={showError && !email}
                          type="text"
                          variant="standard"
                          label="IBAN"
                          value={iban}
                          disabled={disabled}
                          onChange={(value) => setIban(value)}
                        />
                      )}
                    </div>
                  </div>

                  <div className={`cardInfo`}>
                    <section>
                      <Typography variant={"h5"}>Iscrizione</Typography>
                    </section>
                    <div className={`${style.otherInfo}`}>
                      <InputCustom
                        variant="standard"
                        label="Prezzo"
                        value={price ? price + "€" : "Prezzo da definire"}
                        disabled={true}
                        onChange={(value) => setEmail(value)}
                      />
                      <InputCustom
                        variant="standard"
                        label="Validità"
                        value={
                          dateSubStart
                            ? dateSubStart + " - " + dateSubEnd
                            : "Data da definire"
                        }
                        disabled={true}
                        onChange={(value) => setPhone(value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className={style.secondInformation}>
              <div className={style.locationDocument}>
                <div className={"cardInfo"} style={{ minWidth: "330px" }}>
                  <section>
                    <LocationCityIcon />
                    <Typography variant={"h5"}>Località</Typography>
                  </section>

                  <div>
                    <section>
                      <InputCustom
                        error={showError && !address}
                        variant="standard"
                        label="Indirizzo"
                        value={address}
                        disabled={disabled}
                        onChange={(value) => setAddress(value)}
                      />
                      <InputCustom
                        error={showError && !city}
                        variant="standard"
                        label="Città"
                        value={city}
                        disabled={disabled}
                        onChange={(value) => setCity(value)}
                      />
                    </section>
                    <section>
                      <InputCustom
                        error={showError && !CAP}
                        type="number"
                        variant="standard"
                        label="CAP"
                        value={CAP}
                        disabled={disabled}
                        onChange={(value) => setCAP(value)}
                      />
                      <InputCustom
                        error={showError && !province}
                        variant="standard"
                        label="Provincia"
                        value={province}
                        disabled={disabled}
                        onChange={(value) => setProvince(value)}
                      />
                    </section>

                    {(user?.id === id || user?.role === RoleNames.ADMIN) && (
                      <section>
                        <InputCustom
                          variant="standard"
                          label="Partita iva"
                          value={vat_number}
                          disabled={disabled}
                          onChange={(value) => {
                            setVat_number(value);
                            if (value) {
                              if (
                                //value.match("^(IT)?[0-9]{2}[A-Z]?[0-9]{22}$")
                                value.match("^[0-9]{11}$")
                              ) {
                                setVat_numberError(false);
                              } else {
                                setVat_numberError(true);
                              }
                            }
                          }}
                          error={vat_numberError || (showError && !vat_number)}
                        />
                      </section>
                    )}
                  </div>
                </div>
                {user && user.role !== RoleNames.PARTNER && (
                  <div className="cardInfo">
                    <section>
                      <ArticleIcon />
                      <Typography variant="h5">Documenti</Typography>
                    </section>
                    {id ? (
                      <div className={style.document}>
                        <InputUploadFile
                          onDelete={(id) => {
                            deleteFile(id).then(() => {
                              getData();
                              setAtto_costitutivoEmpty(true);
                            });
                          }}
                          value={atto_costitutivo}
                          label="Atto costitutivo"
                          disabled={disabled}
                          file={(file) => {
                            //setAtto_costitutivo(file.base64);
                            sendFile(file, "association", id!, fileTypes.aoa);
                            setAtto_costitutivoEmpty(false);
                          }}
                        />
                        <InputUploadFile
                          onDelete={(id) => {
                            deleteFile(id).then(() => {
                              getData();
                              setStatutoEmpty(true);
                            });
                          }}
                          value={statuto}
                          label="Statuto"
                          disabled={disabled}
                          file={(file) => {
                            //setStatuto(file.base64);
                            sendFile(
                              file,
                              "association",
                              id!,
                              fileTypes.statuto
                            );
                            setStatutoEmpty(false);
                          }}
                        />
                        <InputUploadFile
                          onDelete={(id) => {
                            deleteFile(id).then(() => {
                              getData();
                            });
                          }}
                          value={Rappresentante}
                          label="Rappresentante"
                          disabled={disabled}
                          file={(file) => {
                            //setRappresentante(file.base64);
                            sendFile(
                              file,
                              "association",
                              id!,
                              fileTypes.indentita
                            );
                          }}
                        />
                        <InputUploadFile
                          onDelete={(id) => {
                            deleteFile(id).then(() => {
                              getData();
                            });
                          }}
                          value={logo_ricevuta}
                          label="logo fattura"
                          disabled={disabled}
                          file={(file) => {
                            //setAltro(file.base64);
                            sendFile(
                              file,
                              "association",
                              id!,
                              fileTypes.receipt_logo
                            );
                          }}
                        />
                        <InputUploadFile
                          onDelete={(id) => {
                            deleteFile(id).then(() => {
                              getData();
                            });
                          }}
                          value={altro}
                          label="altro"
                          disabled={disabled}
                          file={(file) => {
                            //setAltro(file.base64);
                            sendFile(file, "association", id!, fileTypes.other);
                          }}
                        />
                      </div>
                    ) : (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography className="text" variant="caption">
                          Completa la creazione
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>
                <TableService association={id!} disabled={disabled} />
                <TableAssociationAdmins disabled={disabled} />
              </div>
            </section>
          </div>
          <div
            style={{
              borderTop: "1px solid #adadad",
              marginTop: "2em",
              width: "100%",
            }}
          >
            {(user?.role === RoleNames.ADMIN || user?.id === id) && (
              <StripeKeyForm
                placeholderKeys={placeholderKeys}
                onSave={() => {
                  getData();
                }}
                association={{
                  endDate: dateSubEnd,
                  price: price ? price.toString() : "",
                  startDate: dateSubStart,
                  disabled: disabled,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <ModalConfirm
        onClose={() => setOpenModalCancel(false)}
        onConfirm={() => {
          disableAssociation(id!).then(() => {
            setOpenModalCancel(false);
            getData();
          });
        }}
        open={openModalCancel}
        title={`Sicuro di voler ${
          enabled ? "disabilitare" : "abilitare"
        } l'associazione`}
      />
    </div>
  );
}

export default AssociationEdit;
