import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authContext } from "../../context/auth";

//MUI
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
//style
import style from "./tablePartnerPayment.module.scss";
import { StyledTableRow } from "../TableCustom/TableCustom";

//icon
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";

//component function
import ModalPaymentSubscription from "../ModalPaymentSubscription/ModalPaymentSubscription";

//api
import {
  createSubscription,
  downloadPdf,
  getSubscriptionByPartner,
} from "../../models/subscription";
//utils
import { stringDate } from "../../utils/stringDate";
import { RoleNames } from "../../utils/roles";
import { downloadFile } from "../../utils/downloadFile";
import { getReceipt } from "../../models/partner";
import CloseIcon from "@mui/icons-material/Close";
import { subType } from "../../utils/subsriptionType";

interface Props {
  newStatus: (status: string) => void;
  refresh: boolean;
}
let maxRow = 10;

const labelStatus = {
  new: "Nuovo Iscrizione",
  active: "Pagato", //tra il socio e l'associazione c'è una iscrizione valida e pagata(locale e online)
  pending: "Attesa Pagamento", //l'associazione ha accettato l'iscrizione inviata dal socio e sta spettando il pagamento online
  "not active": "Scaduto", //ultima iscrizione all'associazione è scaduta
};

function TablePartnerPayment({ newStatus, refresh }: Props) {
  const { id } = useParams();
  const { user } = useContext(authContext);

  const [addRow, setAddRow] = useState<boolean>(false);
  const [statusNew, setStatusNew] = useState<boolean>(false);

  const [payment, setPayment] = useState<any[]>([]);

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [openError, setOpenError] = React.useState<boolean>(false);

  const [error, setError] = React.useState<string>("");

  const emptyError = () => {
    setOpenError(true);
  };
  const closeEmptyError = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };
  const priceSnack1 = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeEmptyError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  useEffect(() => {
    getData();
  }, [refresh]);

  function getData(take = 10, page = 1) {
    getSubscriptionByPartner(id!, { take: take, page: page }).then(
      (response) => {
        const data: any[] = [];
        maxRow = response.data.total;
        //console.log(response);
        response.data.data.forEach((element: any) => {
          if (
            element.status === subType.new &&
            user?.role !== RoleNames.PARTNER
          ) {
            newStatus(element.id);
            setStatusNew(true);
            return;
          }
          data.push({
            associationId: element.associationId,
            end_validity: stringDate(element.end_validity, true),
            id: element.id,
            partnerId: element.partnerId,
            start_validity: stringDate(element.start_validity, true),
            status: element.status,
            serial_number: element.serial_number,
            num_receipt: element.num_receipt,
            associationName: element.association.name,
          });
        });
        setPayment(data);
      }
    );
  }

  return (
    <div className={style.container}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openError}
        autoHideDuration={6000}
        onClose={closeEmptyError}
        message="documenti non generati"
        action={priceSnack1}
        key={"verbal"}
      >
        <Alert
          onClose={closeEmptyError}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <section>
        <Typography variant="h5">Storico Pagamenti Tesseramento</Typography>
        {user?.role === RoleNames.ASSOCIATION && !statusNew && (
          <IconButton size="large" onClick={() => setAddRow(true)}>
            <AddCircleOutlinedIcon fontSize="large" color="success" />
          </IconButton>
        )}
      </section>
      <TableContainer component={Paper}>
        <Table className={style.table}>
          <TableHead>
            <TableRow className={style.rowHead}>
              {user?.role === RoleNames.PARTNER && (
                <TableCell size="small" className={style.head}>
                  Associazione
                </TableCell>
              )}
              <TableCell size="small" className={style.head}>
                Inizio Validità
              </TableCell>
              <TableCell size="small" className={style.head}>
                Fine Validità
              </TableCell>
              <TableCell size="small" className={style.head}>
                Status
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payment.map((row: any) => {
              const status: "active" | "pending" | "not active" | "new" =
                row.status;
              return (
                <StyledTableRow key={row.id}>
                  {user?.role === RoleNames.PARTNER && (
                    <TableCell>{row.associationName}</TableCell>
                  )}
                  <TableCell>{row.start_validity}</TableCell>
                  <TableCell>{row.end_validity}</TableCell>
                  <TableCell>{labelStatus[status]}</TableCell>

                  <TableCell>
                    {(row.status === subType.active ||
                      row.status === subType.pending) &&
                      user?.role !== RoleNames.PARTNER && (
                        <IconButton
                          onClick={() =>
                            downloadPdf(row.id)
                              .then((response) => {
                                downloadFile(
                                  response.data,
                                  "verbale_" +
                                    row.serial_number +
                                    "/" +
                                    row.start_validity
                                      .split(",")[0]
                                      .split("/")[2] +
                                    ".pdf"
                                );
                              })
                              .catch((error) => {
                                setError("verbale non ancora generato");
                                emptyError();
                              })
                          }
                        >
                          <DownloadIcon />
                        </IconButton>
                      )}
                    {row.status === subType.active && (
                      <IconButton
                        onClick={() =>
                          getReceipt("subscription", row.id)
                            .then((response) => {
                              downloadFile(
                                response.data,
                                "fattura_" + row.num_receipt + ".pdf"
                              );
                            })
                            .catch((error) => {
                              setError("Fattura non ancora generata");
                              emptyError();
                            })
                        }
                      >
                        <RequestQuoteOutlinedIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { value: maxRow, label: "All" },
                ]}
                count={maxRow ? maxRow : -1}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={(event, newPage: number) => {
                  setPage(newPage);
                  getData(rowsPerPage, newPage + 1);
                }}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                  getData(parseInt(event.target.value, 10), 1);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ModalPaymentSubscription
        onConfirm={(sub) => {
          createSubscription({
            associationId: user?.id!,
            partnerId: id!,
            start_validity: sub.startDate,
            end_validity: sub.endDate,
            status: sub.status,
          }).then(() => {
            setAddRow(false);
            getData();
          });
        }}
        onClose={() => setAddRow(false)}
        open={addRow}
      />
    </div>
  );
}

export default TablePartnerPayment;
