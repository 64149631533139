import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authContext } from "../../context/auth";
//style
import style from "./tableAssociationAdminsStyle.module.scss";
//mui
import {
  Button,
  Chip,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
//utils
import { RoleNames } from "../../utils/roles";
//api
import {
  getAssociationInfo,
  updateAssociationAdmins,
} from "../../models/association";
//icon
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import InfoIcon from "@mui/icons-material/Info";

interface Props {
  disabled: boolean;
}

function TableAssociationAdmins({ disabled }: Props) {
  const { id } = useParams();
  const { user } = useContext(authContext);

  const [error, setError] = useState<boolean>(false);

  const [addRow, setAddRow] = useState<boolean>(false);
  const [newService, setNewService] = useState<string>("");
  const [associationAdminsArray, setAssociationAdminsArray] = useState<
    string[]
  >([]);

  useEffect(() => {
    id && getData();
  }, []);

  function getData() {
    let data = "";
    getAssociationInfo(id!).then((response) => {
      //console.log(response);

      data = response.data.associationAdmins!;
      setAssociationAdminsArray(
        data !== "" && data !== null ? data.split(",") : []
      );
    });
  }

  return (
    <div className={style.container + " cardInfo"}>
      <section
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {addRow ? (
          <>
            <TextField
              error={error}
              onChange={(e) => {
                setError(false);
                setNewService(e.target.value);
              }}
              size="small"
            />

            {(user?.id === id || user?.role === RoleNames.ADMIN) &&
              !disabled &&
              id && (
                <>
                  {addRow ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        if (newService === "") {
                          setError(true);
                          return;
                        }
                        const data = associationAdminsArray.slice();
                        data.push(newService);
                        updateAssociationAdmins(id!, data.toString()).then(
                          () => {
                            setAddRow(false);
                            getData();
                          }
                        );
                      }}
                    >
                      Salva
                    </Button>
                  ) : (
                    <IconButton
                      style={{ padding: 0 }}
                      onClick={() => setAddRow(true)}
                    >
                      <AddCircleOutlinedIcon color="success" />
                    </IconButton>
                  )}
                </>
              )}
          </>
        ) : (
          <div>
            <Typography variant="h5">
              Consiglieri
              <Tooltip title="Aggiungere un consigliere alla volta">
                <InfoIcon fontSize="small" />
              </Tooltip>
              {(user?.id === id || user?.role === RoleNames.ADMIN) &&
                !disabled &&
                id && (
                  <>
                    {addRow ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (newService === "") {
                            setError(true);
                            return;
                          }
                          const data = associationAdminsArray.slice();
                          data.push(newService);
                          updateAssociationAdmins(id!, data.toString()).then(
                            () => {
                              setAddRow(false);
                              getData();
                            }
                          );
                        }}
                      >
                        Salva
                      </Button>
                    ) : (
                      <IconButton
                        style={{ padding: 12 }}
                        onClick={() => setAddRow(true)}
                      >
                        <AddCircleOutlinedIcon color="success" />
                      </IconButton>
                    )}
                  </>
                )}
            </Typography>
            <Typography variant="caption">
              Il primo verrà utilizzato come segretario
            </Typography>
          </div>
        )}
      </section>

      <div className={style.service}>
        {associationAdminsArray.length > 0 ? (
          associationAdminsArray.map((element, key) => {
            return (
              <Chip
                label={element}
                onDelete={
                  !disabled
                    ? () => {
                        const array = associationAdminsArray.slice();
                        array.splice(key, 1);
                        updateAssociationAdmins(id!, array.toString()).then(
                          () => {
                            //setDisabled(true);
                            getData();
                          }
                        );
                      }
                    : undefined
                }
              />
            );
          })
        ) : id ? (
          <section>
            <Typography>Nessun Consigliere inserito</Typography>
          </section>
        ) : (
          <section>
            <Typography>Completa la creazione</Typography>
          </section>
        )}
      </div>
    </div>
  );
}

export default TableAssociationAdmins;
