import React, { useState } from "react";
import CardAuth from "../../components/CardAuth/CardAuth";
import InputCustom from "../../components/InputCustom/InputCustom";
import { Button } from "@mui/material";
import BackgroundLine from "../../components/BackgroundLine/BackgroundLine";
import {
  changePassword,
  sendNotificationPassword,
} from "../../models/association";
import { useParams } from "react-router-dom";

function ResetPassword() {
  const { id } = useParams();

  const [reset, setReset] = useState<string>("");
  const [resetPassword, setResetPassword] = useState<string>("");
  const [ripetiPassword, setRipetiPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  return (
    <div className={"pageContainer pageCenter"}>
      {id ? (
        <CardAuth
          title={"Password Reset"}
          buttons={
            <Button
              onClick={() => {
                if (resetPassword !== ripetiPassword) {
                  setError(true);
                }
                changePassword(id, resetPassword);
              }}
              variant="contained"
            >
              Invia
            </Button>
          }
        >
          <>
            <InputCustom
              type="password"
              error={error}
              value={resetPassword}
              label="Nuova Password"
              onChange={(value) => {
                setError(false);
                setResetPassword(value);
              }}
            />
            <InputCustom
              type="password"
              error={error}
              value={ripetiPassword}
              label="Ripeti Password"
              onChange={(value) => {
                setError(false);
                setRipetiPassword(value);
              }}
            />
          </>
        </CardAuth>
      ) : (
        <CardAuth
          title={"Password Reset"}
          buttons={
            <Button
              onClick={() => sendNotificationPassword(reset)}
              variant="contained"
            >
              Invia
            </Button>
          }
        >
          <>
            <InputCustom
              value={reset}
              label="email/codice fiscale"
              onChange={(value) => setReset(value)}
            />
          </>
        </CardAuth>
      )}
      <BackgroundLine />
    </div>
  );
}

export default ResetPassword;
