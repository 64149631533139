import api from "./api";

export type Type = {
  name: string;
  id: string;
};

export const getType = () => {
  return api.get("/type");
};

export const addType = (type: string) => {
  return api.post("/type", { name: type });
};

export const deleteType = (idtype: string) => {
  return api.delete("/type/" + idtype);
};
