import React, { useEffect, useState } from "react";

import style from "./associationListStyle.module.scss";

import img from "../../assets/sport.svg";
//api
import { Association, getAllAssociations } from "../../models/association";
//component function
import CustomCard from "../../components/CustomCard/CustomCard";
//mui
import SearchIcon from "@mui/icons-material/Search";
import {
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";

function AssociationsList() {
  const [associations, setAssociations] = useState<Association[]>([]);

  const [totalPage, setTotalPage] = useState<number>(0);

  useEffect(() => {
    getData();
  }, []);

  const getData = (page = 1, search = "") => {
    getAllAssociations({ take: 12, page: page }, search).then((response) => {
      const data: Association[] = [];
      response.data.data.forEach((element: Association) => {
        data.push({
          address: element.address,
          cap: element.cap,
          iban: element.iban,
          city: element.city,
          email: element.email,
          nameAnagrafica: element.nameAnagrafica,
          surnameAnagrafica: element.surnameAnagrafica,
          logo: element.logo,
          name: element.name,
          phone_number: element.phone_number,
          province: element.province,
          start_date: element.start_date,
          vat_number: element.vat_number,
          id: element.id,
          price: element.price,
        });
      });
      setTotalPage(response.data.total_pages);
      setAssociations(data);
    });
  };

  return (
    <div className={style.page + " pageContainer pageCenter"}>
      <div className={style.head}>
        <section>
          <Typography variant="h4">Le nostre associazioni</Typography>
          <Typography variant="subtitle1">
            Scegli tra le associazioni. Puoi filtrare per locazione o cercare il
            nome.
          </Typography>
        </section>
        <section className={style.field}>
          <TextField
            size="small"
            style={{ width: "100%" }}
            onChange={(e) => getData(1, e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </section>
      </div>

      <section className={style.pageContent}>
        {associations.map((association) => {
          return (
            // <CardAssociation key={association.id} association={association} />
            <CustomCard
              key={association.id}
              association={association}
            ></CustomCard>
          );
        })}
      </section>
      <Pagination
        variant="outlined"
        color="primary"
        count={totalPage}
        onChange={(e, value) => getData(value)}
        style={{ alignSelf: "center" }}
        className={style.pagination}
      />
    </div>
  );
}

export default AssociationsList;
