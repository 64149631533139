import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authContext } from "../../context/auth";
//style
import style from "./partenrListStyle.module.scss";
//functional Componet
import TableCustom, { Columns } from "../../components/TableCustom/TableCustom";
//mui
import {
  Alert,
  Button,
  IconButton,
  Modal,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
//icon
import EditIcon from "@mui/icons-material/Edit";
import excel from "../../assets/excel.svg";
import InfoIcon from "@mui/icons-material/Info";
//utils
import PATHS from "../../utils/paths";
import { stringDate } from "../../utils/stringDate";
//api
import {
  Partner,
  downloadPartner,
  getAllPartnerByAssociation,
  uploadPartner,
} from "../../models/partner";
import { downloadFile } from "../../utils/downloadFile";

let paginationMemory = { take: 10, page: 1 };

function PartnerList() {
  const { user } = useContext(authContext);
  const navigate = useNavigate();
  const [partner, setPartner] = useState<any[]>([]);
  const [totalRow, setTotalRow] = useState<number>(0);
  const [modalExcel, setModalExcel] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [columns, setColumns] = useState<Columns[]>([]);

  useEffect(() => {
    setColumns([
      {
        name: "internalId",
        label: (
          <div>
            Numero Progressivo
            <Tooltip
              placement="top"
              title="Cliccare sul numero Progressivo per modificarlo"
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </div>
        ),
      },
      { name: "email", label: "Email" },
      { name: "phone_number", label: "Telefono" },
      {
        name: "status",
        label: "Stato",
        status: {
          value: [
            { label: "Tesserato", name: "active" },
            { label: "Pending", name: "pending" },
            { label: "Non attivo", name: "not active" },
            { label: "Nuovo", name: "new" },
          ],
          green: "active",
          gray: "new",
          red: "not active",
          yellow: "pending",
        },
      },
      { name: "end_validity", label: "Fine validità" },

      {
        name: "actions",
        label: (
          <Tooltip title="Download e Upload">
            <IconButton onClick={() => setModalExcel(true)}>
              <img src={excel} alt="excel" style={{ width: "24px" }} />
            </IconButton>
          </Tooltip>
        ),
        action: (row: any) => (
          <>
            <IconButton
              onClick={() => navigate(PATHS.partnerEdit + "/" + row.id)}
            >
              <EditIcon />
            </IconButton>
          </>
        ),
      },
    ]);
    getData();
  }, []);

  function getData(take = 10, page = 1, q = "") {
    getAllPartnerByAssociation(
      { take: take, page: page, q: q },
      user?.id!
    ).then((response) => {
      const data: Partner[] = [];
      response.data.data.forEach((element: any) => {
        data.push({
          email: element.email,
          end_validity: element.subcription.end_validity
            ? stringDate(element.subcription.end_validity)
            : "",
          phone_number: element.phone_number,
          id: element.id,
          status: element.subcription.status,
          address: element.address,
          cap: element.cap,
          city: element.city,
          province: element.province,
          internalId: element.internal_id,
          name: element.name,
          surname: element.surname,
          internal_id_duplicate: element.internal_id_duplicate,
        });
      });
      setTotalRow(response.data.total);
      setPartner(data);
    });
  }
  return (
    <div className={"pageContainer " + style.container}>
      {/* <SnackNotActive /> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
        className={style.button}
      >
        <TextField
          label="Cerca per n. progressivo"
          onChange={(e) =>
            getData(
              paginationMemory.take,
              paginationMemory.page,
              e.target.value
            )
          }
        ></TextField>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Numero Soci</Typography>
          <Typography>
            {totalRow} / {user?.max_partner}
          </Typography>
        </div>
        <Button
          disabled={!user?.status}
          style={{ backgroundColor: style.secondaryColor, color: "white" }}
          onClick={() => navigate(PATHS.partnerAdd)}
          variant="contained"
        >
          <Typography variant="button">Aggiungi Socio</Typography>
        </Button>
      </div>

      <TableCustom
        refresh={() => getData(paginationMemory.take, paginationMemory.page)}
        columns={columns}
        rows={partner}
        pagination={{
          defaultTake: 10,
          maxRows: totalRow,
          onChange(take, page) {
            paginationMemory = { take: take, page: page + 1 };
            getData(take, page + 1);
          },
        }}
      />
      <Modal
        open={modalExcel}
        onClose={() => setModalExcel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modalConfirm">
          <h3>Scegli che operazione vuoi fare</h3>
          <div className="sectionButtonModal">
            <Button
              variant="contained"
              onClick={() => {
                downloadPartner(false).then((response) => {
                  const date = new Date();
                  downloadFile(
                    response.data,
                    "storico_soci_" +
                      date.getDate() +
                      "/" +
                      date.getMonth() +
                      "/" +
                      date.getFullYear() +
                      ".xlsx"
                  );
                });
                setModalExcel(false);
              }}
            >
              Download excel
            </Button>
            <Tooltip
              placement="top"
              title={
                <span>
                  Segui il{" "}
                  <span
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      downloadPartner(true).then((response) => {
                        const date = new Date();
                        downloadFile(
                          response.data,
                          "template_storico_soci_" +
                            date.getDate() +
                            "/" +
                            date.getMonth() +
                            "/" +
                            date.getFullYear() +
                            ".xlsx"
                        );
                      })
                    }
                  >
                    template
                  </span>{" "}
                  per caricare i dati correttamente.
                </span>
              }
            >
              <Button
                variant="outlined"
                onClick={() => {
                  const fileInput = document.createElement("input");
                  fileInput.type = "file";
                  fileInput.accept = ".xls, .xlsx";
                  document.body.appendChild(fileInput);
                  fileInput.click();

                  fileInput.addEventListener("change", (event: any) => {
                    const file = event.target.files[0];

                    const data = new FormData();
                    data.append("file", file);

                    uploadPartner(data).then(() => {
                      document.body.removeChild(fileInput);
                      setModalExcel(false);
                      setOpen(true);
                      getData();
                    });
                  });
                }}
              >
                Upload excel
              </Button>
            </Tooltip>
          </div>
        </div>
      </Modal>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Carico soci Completato con successo
        </Alert>
      </Snackbar>
    </div>
  );
}

export default PartnerList;
