import React, { useContext, useState } from "react";
import CardAuth from "../../components/CardAuth/CardAuth";
import { Button, TextField, IconButton, Snackbar, Alert } from "@mui/material";
import BackgroundLine from "../../components/BackgroundLine/BackgroundLine";
import { createAssociationInfo } from "../../models/association";
import { useNavigate } from "react-router-dom";
import PATHS from "../../utils/paths";
import InputPassword from "../../components/InputPassword/InputPassword";
import { login } from "../../models/auth";
import { authContext } from "../../context/auth";
import { me } from "../../models/user";
import CloseIcon from "@mui/icons-material/Close";

function AssociationSignin() {
  const { setToken, setRefresh, setUser, user } = useContext(authContext);
  const navigate = useNavigate();

  const [denominazione, setDenominazione] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [indirizzo, setIndirizzo] = useState<string>("");
  const [città, setCittà] = useState<string>("");
  const [CAP, setCAP] = useState<string>("");
  const [provincia, setProvincia] = useState<string>("");
  const [telefono, setTelefono] = useState<string>("");

  const [pIva, setPIva] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [ripetiPassword, setRipetiPassword] = useState<string>("");
  const [openError, setOpenError] = React.useState<boolean>(false);

  const [error, setError] = React.useState<string>("");

  const emptyError = () => {
    setOpenError(true);
  };
  const closeEmptyError = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };
  const priceSnack1 = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeEmptyError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className={"pageContainer pageCenter"}>
      <CardAuth
        title={"Registrazione Associazione"}
        buttons={
          <Button
            onClick={() => {
              if (password !== ripetiPassword) {
                setError("le password non corrispondono");
                emptyError();
                return;
              } else if (
                indirizzo === "" ||
                CAP === "" ||
                città === "" ||
                email === "" ||
                denominazione === "" ||
                telefono === "" ||
                provincia === "" ||
                pIva === "" ||
                password === ""
              ) {
                setError("nessun campo può essere vuoto");
                emptyError();
                return;
              } else if (!pIva.match("^[0-9]{11}$")) {
                setError("formato partita iva non valido");
                emptyError();
                return;
              }
              createAssociationInfo({
                address: indirizzo,
                iban: "",
                cap: CAP,
                city: città,
                email: email,
                name: denominazione,
                phone_number: telefono,
                province: provincia,
                vat_number: pIva,
                password: password,
              }).then(() => {
                login({ user: email, pass: password })
                  .then((res: any) => {
                    if (res.status === 201) {
                      const json = res.data;
                      setToken(json.authorization);
                      setRefresh(json.refresh);
                    }
                  })
                  .then(me)
                  .then((res) => {
                    setUser(res.data);
                    navigate(PATHS.partnerList);
                  });
              });
            }}
            variant="contained"
          >
            Sign in
          </Button>
        }
      >
        <>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openError}
            autoHideDuration={6000}
            onClose={closeEmptyError}
            message="inserire statuto"
            action={priceSnack1}
            key={"insert_status"}
          >
            <Alert
              onClose={closeEmptyError}
              severity="warning"
              sx={{ width: "100%" }}
            >
              errore: {error}
            </Alert>
          </Snackbar>
          <TextField
            size="small"
            value={denominazione}
            label="Denominazione"
            onChange={(e) => setDenominazione(e.target.value)}
          />
          <TextField
            type="email"
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
          />
          <TextField
            size="small"
            value={indirizzo}
            onChange={(e) => setIndirizzo(e.target.value)}
            label="Indirizzo"
          />
          <TextField
            size="small"
            value={città}
            onChange={(e) => setCittà(e.target.value)}
            label="Città"
          />
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <TextField
              type="number"
              size="small"
              value={CAP}
              onChange={(e) => setCAP(e.target.value)}
              label="CAP"
            />
            <TextField
              size="small"
              value={provincia}
              onChange={(e) => setProvincia(e.target.value)}
              label="Provincia"
            />
          </div>
          <TextField
            type="tel"
            size="small"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            label="Telefono"
          />
          <TextField
            size="small"
            value={pIva}
            onChange={(e) => setPIva(e.target.value)}
            label="Partita Iva"
          />

          <InputPassword
            value={password}
            onChange={(value) => setPassword(value)}
          />
          <InputPassword
            value={ripetiPassword}
            label="Ripeti Password"
            onChange={(value) => setRipetiPassword(value)}
          />
        </>
      </CardAuth>
      <BackgroundLine />
    </div>
  );
}

export default AssociationSignin;
