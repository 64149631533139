import React, { useContext, useState } from "react";
import style from "./partnerAdd.module.scss";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import PATHS from "../../utils/paths";
import { Typography, IconButton, Snackbar, Alert } from "@mui/material";
import { checkPartner, sendEmailPartner } from "../../models/partner";
import ModalConfirm from "../../components/ModalConfirm/ModalConfirm";
import { getAssociationInfo } from "../../models/association";
import CloseIcon from "@mui/icons-material/Close";
import { authContext } from "../../context/auth";

export default function PartnerAdd() {
  const { user } = useContext(authContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState<boolean>(false);

  const [taxId, setTaxtId] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [error, setError] = React.useState<string>("");
  const [openError, setOpenError] = React.useState<boolean>(false);

  const emptyError = () => {
    setOpenError(true);
  };
  const closeEmptyError = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };
  const priceSnack1 = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeEmptyError}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const calcolaEta = () => {
    if (taxId.length === 16) {
      const annoDiNascita = taxId.substring(6, 8);
      const meseDiNascitaChar = taxId.substring(8, 9).toUpperCase();
      const giornoDiNascita = taxId.substring(9, 11);

      //console.log(annoDiNascita, meseDiNascitaChar, giornoDiNascita);

      // Converte il carattere del mese in un numero corrispondente
      const mesi: any = {
        A: "01",
        B: "02",
        C: "03",
        D: "04",
        E: "05",
        H: "06",
        L: "07",
        M: "08",
        P: "09",
        R: "10",
        S: "11",
        T: "12",
      };
      const meseDiNascita = mesi[meseDiNascitaChar];

      const currentYear = new Date().getFullYear() - 2000;

      const secolo = Number(annoDiNascita) <= currentYear - 10 ? "20" : "19";

      return (
        secolo + annoDiNascita + "-" + meseDiNascita + "-" + giornoDiNascita
      );
    } else {
    }
  };

  function validaCodiceFiscale(cf: string) {
    cf = cf.toUpperCase();
    var validi, i, s, set1, set2, setpari, setdisp;
    if (cf === "") return "";
    cf = cf.toUpperCase();
    if (cf.length !== 16) return false;
    validi = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    for (i = 0; i < 16; i++) {
      if (validi.indexOf(cf.charAt(i)) === -1) return false;
    }
    set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
    s = 0;
    for (i = 1; i <= 13; i += 2)
      s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    for (i = 0; i <= 14; i += 2)
      s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
    //if (s % 26 !== cf.charCodeAt(15) - "A".charCodeAt(0)) return false;
    return true;
  }

  function validationRegexCF() {
    const regexCF = "/^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/gm";
  }

  return (
    <div className={"pageContainer " + style.card}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openError}
        autoHideDuration={6000}
        onClose={closeEmptyError}
        message="inserire statuto"
        action={priceSnack1}
        key={"insert_status"}
      >
        <Alert
          onClose={closeEmptyError}
          severity="warning"
          sx={{ width: "100%" }}
        >
          errore: {error}
        </Alert>
      </Snackbar>
      <div className="cardInfo">
        <section>
          <Typography variant="h5">Iscrizione nuovo Socio</Typography>
        </section>
        <div>
          <Stack>
            <TextField
              onChange={(e) => setTaxtId(e.target.value)}
              className={style.TextFields1}
              variant="outlined"
              label="codice fiscale"
              margin="normal"
            ></TextField>
            <TextField
              onChange={(e) => setEmail(e.target.value)}
              className={style.TextFields2}
              variant="outlined"
              label="e-mail"
            ></TextField>
          </Stack>
          <div className={style.buttons}>
            <Stack spacing={5} direction="row">
              <Button
                onClick={() => navigate(-1)}
                color="error"
                variant="contained"
                size="large"
              >
                indietro
              </Button>
              <Button
                onClick={() =>
                  checkPartner({ tax_id_code: taxId, email: email }).then(
                    (response) => {
                      if (response.data.status === 302) {
                        setOpen(true);
                      } else if (response.data.status === 200) {
                        if (!validaCodiceFiscale(taxId)) {
                          setError("formato codice fiscale errato");
                          emptyError();
                        } else if (
                          !email.match(
                            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
                          )
                        ) {
                          setError("email non valida");
                          emptyError();
                        } else {
                          navigate(PATHS.partnerEdit, {
                            state: {
                              taxId: taxId,
                              email: email,
                              date: calcolaEta(),
                            },
                          });
                        }
                      }
                    }
                  )
                }
                variant="contained"
                size="large"
              >
                avanti
              </Button>
            </Stack>
          </div>
        </div>
      </div>
      <ModalConfirm
        onClose={() => setOpen(false)}
        onConfirm={() => {
          getAssociationInfo(user?.id!).then((response) => {
            sendEmailPartner({
              associationId: user?.id!,
              associationName: response.data.name,
              partnerEmail: email,
              tax_id_code: taxId,
            })
              .then(() => navigate(PATHS.partnerList))
              .catch((e) => {
                console.log(e);

                if (e.response.status !== 400) {
                  setError("l'email non corrisponde al CF inserito");
                  emptyError();
                }
              });
          });
        }}
        open={open}
        title={
          "L'utente con questo codice fiscale è già presente a sistema. Puoi collegarlo all'associazione, ma prima di vedere i suoi dati personali l'utente dovrà accettare il trattamento degli stessi tramite email che riceverà a questo indirizzo."
        }
      />
      <div className={"background"}></div>
    </div>
  );
}
