import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { getSubscriptionById } from "../../models/subscription";
import { useParams } from "react-router-dom";
import { getAssociationInfo, getPrice } from "../../models/association";

type Subscrition = {
  name: string;
  start_date: string;
  end_date: string;
  price: string;
};

function SendSubPartner() {
  const { idSub } = useParams();
  const [state, setState] = useState<Subscrition>({
    end_date: "",
    name: "",
    price: "",
    start_date: "",
  });

  useEffect(() => {
    getSubscriptionById(idSub!).then(async (response) => {
      getAssociationInfo(response.data.associationId).then((association) => {
        setState({
          end_date: response.data.end_date,
          name: association.data.name,
          price: association.data.price,
          start_date: response.data.start_date,
        });
      });
    });
  }, []);

  return (
    <div className="pageContainer pageCenter">
      <div className="cardInfo">
        <section>
          <Typography variant="h5">Richiesta iscrizione</Typography>
        </section>
        <div>
          <Typography>Da: {state.name}</Typography>
          <Typography>Data inizio: {state.start_date}</Typography>
          <Typography>Data fine: {state.end_date}</Typography>
          <Typography>Prezzo: {state.price}</Typography>
        </div>
        <section style={{ justifyContent: "space-between" }}>
          <Button variant="outlined" color="error">
            <Typography variant="button">Rifiuta</Typography>
          </Button>
          <Button variant="contained" color="success">
            <Typography variant="button">Accetta</Typography>
          </Button>
        </section>
      </div>
    </div>
  );
}

export default SendSubPartner;
