import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAssociationInfo } from "../../models/association";
import { acceptedPrivacy } from "../../models/subscription";

function PrivacyPartner() {
  const navigate = useNavigate();
  const { idSub } = useParams();

  const [association, setAssociation] = useState<string>("Libro");
  useEffect(() => {
    // getAssociationInfo(idAs!).then((response) => {
    //   setAssociation(response.data.name);
    // });
  }, []);
  return (
    <div className="pageContainer pageCenter">
      <div className="cardInfo" style={{ maxWidth: "50%" }}>
        <section>
          <Typography variant="h5">Richiesta consenso Privacy</Typography>
        </section>
        <div>
          <Typography>
            L'associazione{" "}
            <span style={{ fontWeight: "bold" }}>{association}</span> richiede
            l'accesso ai dati personali per poter proseguire con l'iscrizione.
          </Typography>
          <Typography>Consentire?</Typography>
        </div>
        <section style={{ justifyContent: "space-between" }}>
          <Button variant="outlined" color="error">
            <Typography variant="button">NO</Typography>
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() =>
              acceptedPrivacy(true, idSub!).then(() => navigate("/"))
            }
          >
            <Typography variant="button">SI</Typography>
          </Button>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPartner;
