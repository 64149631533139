import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
//mui
import { Alert, Button, Card, Snackbar, Typography } from "@mui/material";
//img
import hero from "../../assets/sport.svg";
//style
import style from "./homeStyle.module.scss";
import color from "../../utils/commonVariable.module.scss";

import PATHS from "../../utils/paths";
import BackgroundLine from "../../components/BackgroundLine/BackgroundLine";
import { changeSubscription } from "../../models/subscription";

function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmPayment, setConfirmPayment] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("redirect_status") === "succeeded") {
      setConfirmPayment(true);
      changeSubscription({ id: searchParams.get("id")!, status: "active" });
    }
  }, []);

  return (
    <div className={"pageContainer pageCenter"}>
      <Card className={style.page}>
        <div className={style.heroContainer}>
          <img className={style.hero} src={hero} alt="hero" />
        </div>
        <section className={style.text}>
          <section>
            <Typography variant="h3">Il portale del terzo settore</Typography>
            <Typography variant="subtitle2">
              Sei un'associazione? Unisciti al nostro gruppo cliccando{" "}
              <a href={PATHS.associationSignin}>qui</a>
            </Typography>
          </section>
          <section>
            <Typography variant="subtitle2">
              Vuoi diventare socio o sei già iscritto? Scopri le associazioni
              vicine a te o entra nella tua area privata.
            </Typography>
            <Button
              style={{ backgroundColor: color.secondaryColor }}
              variant="contained"
              onClick={() => navigate(PATHS.associationList)}
            >
              <Typography variant="button">Visualizza Associazioni</Typography>
            </Button>
          </section>
        </section>
      </Card>

      <BackgroundLine />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={confirmPayment}
        autoHideDuration={4000}
        onClose={() => {
          setConfirmPayment(false);
        }}
      >
        <Alert variant="filled" severity="success">
          Il pagamento è andato a buon fine
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Home;
