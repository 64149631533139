import api from "./api";
import { Pagination } from "./association";

export type Messages = {
  id: string;
  sender?: "admin" | "association";
  text: string;
  date?: string;
};
export type NewMessages = {
  ticketId: string;
  text: string;
};

export type Ticket = {
  id: string;
  status: "open" | "close";
  start_date: string;
  update_date: string;
  last_message: string;
};
export type NewTicket = {
  associationId: string;
  message: { text: string };
};

//TICKET
export const getAllTicketByAssociation = (
  id: string,
  pagination: Pagination,
  filter: string
) => {
  return api.get("/ticket", {
    params: { take: pagination.take, page: pagination.page, id: id, q: filter },
  });
};

export const closeTicket = (id: string) => {
  return api.put("/ticket", { id: id });
};

export const createNewTicket = (newTicket: NewTicket) => {
  return api.post("/ticket", newTicket);
};

//MESSAGE
export const getMessagesByTicket = (idTicket: string) => {
  return api.get("/ticket/message", { params: { id: idTicket } });
};

export const sendMessages = (newMessage: NewMessages) => {
  return api.post("/ticket/message", newMessage);
};
